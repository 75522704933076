import React, { useState, FunctionComponent, useEffect } from 'react';
import { Select, MenuItem, FormControl, Chip, ListItemText } from '@mui/material';
import Icon from './common/Icon'; // Assuming similar icon handling as the Input component
import Colors from './common/Colors';
import Fonts from './common/Fonts';
import Checkbox from './Checkbox'; // Assuming similar icon handling as the Input component
import Input from "./Input";

type DropdownProps = {
    options: (string | { id: string; value: string })[];
    multiselect?: boolean;
    initialValue?: any; // Optional prop with a default value of null
    onChange?: (event: any) => void; // Optional prop with a default empty function
    small?: boolean;
    errorMessage?: string;
    placeholder?: string;
    width?: string;
    icon?: string;
    rootInput?: boolean;
    onInputChange?: (event: any) => void;
    onInputBlur?: () => void;
};

const Dropdown: FunctionComponent<DropdownProps> = ({
    options,
    multiselect = false,
    initialValue = null, // Default value of null for the optional prop
    onChange = () => {}, // Default empty function for the optional prop
    small = false,
    errorMessage = null,
    placeholder = null,
    width = '100%', //default width is 100%
    icon = null,
    rootInput = null,
    onInputChange = () => {},
    onInputBlur = () => {},
}) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [textInput, settextInput] = useState(false);
    const [selectedValue, setSelectedValue] = useState<string[]>(multiselect ? [] : (initialValue ? [initialValue] : []));

    useEffect(() => {
        if (!multiselect && initialValue !== null) {
            const selectedOption = options.find((option) => option.value === initialValue);
            if (selectedOption) {
                setSelectedValue([initialValue]);
            }
        }
    }, [multiselect, initialValue, options]);

    const handleChange = (event: any) => {
        let selectedOption;
        if (typeof options[0] === 'string') {
            selectedOption = options.find((option) => option === event.target.value);
        } else {
            selectedOption = options.find((option) => option.id === event.target.value);
        }
        const value = selectedOption ? (typeof selectedOption === 'string' ? selectedOption : selectedOption.value) : "";
        setInputValue(value);
        onChange(selectedOption);
    };

    const toggleDropdown = () => {
        setMenuOpen(!menuOpen);
    };

    const getValue = () => {
        let value;
        if (multiselect) {
          value = selectedValue.length === 0 ? renderPlaceholder() : renderValue(selectedValue);
        } else {
          value = selectedValue.length === 0 ? renderPlaceholder() : selectedValue[0];
        }
        return value;
    };

    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (!multiselect && initialValue !== null && !inputValue) {
            setInputValue(initialValue);
        }
    }, [multiselect, initialValue, inputValue]);

    const handleInputChange = (newValue: string) => {
        setInputValue(newValue);
        onInputChange(newValue);
    };

    const handleInputBlur = () => {
        onInputBlur();
    };

    // Function to render value with Chips for multiselect
    const renderValue = (selected: any) => (
        <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '5px' }}>
            {selected.map((value: string) => {
                const label = typeof value === 'string' ? value : value.value;
                return (
                    <Chip key={label} label={label} size="small" style={{
                        backgroundColor: Colors.paleBright,
                        color: Colors.blueDark,
                        fontFamily: Fonts.outfitRegular.fontFamily,
                        fontWeight: Fonts.outfitRegular.fontWeight,
                        fontSize: "14px",
                        height: small ? "20px" : "36px",
                        borderRadius: small ? "10px" : "18px",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                        left: "-10px",
                        position: "relative",
                        zIndex: 11
                    }} />
                );
            })}
        </div>
    );

    const renderPlaceholder = () => {
        return <span style={{ color: Colors.grayMed }}>{placeholder}</span>;
    }

    return (
        <FormControl fullWidth variant="outlined" sx={{
            width: width,
            // width: '146px',
            '& .MuiOutlinedInput-root': {
                height: small ? '36px' : '52px',
                borderRadius: '121px',
                backgroundColor: '#fff',
                padding: '0px',
                cursor: rootInput ? 'text' : 'pointer',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: menuOpen ? Colors.blue : Colors.grayLight,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: Colors.grayDark,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: Colors.grayLight,
                },
                zIndex: 11
            },
            '& .MuiSelect-select': {
                cursor: rootInput ? 'text' : 'pointer',
                paddingLeft: small ? '15px' : '20px',
                display: 'flex',
                alignItems: 'flex-start',
                fontSize: small ? "14px" : "18px",
                fontFamily: Fonts.outfitRegular.fontFamily,
                color: Colors.grayDark,
                zIndex: 11,
            },
            '& .MuiSvgIcon-root': { // Adjust MUI Select icon styles if necessary
                color: Colors.grayDark,
            },
            '& .MuiFormHelperText-root': { // Style the form helper text similar to the Input component
                marginLeft: "20px",
                fontFamily: Fonts.outfitRegular.fontFamily,
                fontWeight: Fonts.outfitRegular.fontWeight,
                fontSize: "10px",
                color: Colors.red,
            },
            zIndex: 11
        }}>
            {icon && (
            <div style={{ position: 'absolute', left: '16px', top: small ? '8px' : '14px', zIndex: 12 }}>
                <Icon icon={icon} size={small ? 20 : 24} color={Colors.grayDark} />
            </div>
            )}
            {rootInput && textInput ? ( 
            <div style={{ marginTop: '16px', zIndex: '20' }}>
                <Input key={inputValue}
                    autoFocus
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    width={width}
                />
                <div style={{ position:'absolute', top: '24px', right: '14px', cursor:'pointer', padding: '10px 6px 0px 6px', zIndex:'20', borderRadius:'20px', backgroundColor: 'white' }}>
                    <Icon icon={menuOpen ? "up" : "down"} size={18} color={Colors.grayDark} onClick={()=>{settextInput(false); toggleDropdown();}} />
                </div>
            </div>
        ) : (
            <Select
                sx={{ zIndex: 11, 
                    cursor: rootInput ? 'text' : 'pointer',
                    '& .MuiSelect-select': {
                        paddingLeft: small ? '15px' : '20px', 
                    },
                }}
                open={menuOpen}
                multiple={multiselect}
                value={selectedValue}
                onChange={handleChange}
                onOpen={() => {
                    if (rootInput) { 
                        settextInput(true); 
                        const inputElement = document.getElementById('dropdown-input');
                        inputElement?.focus();
                    }
                    else {
                        setMenuOpen(true);
                    }
                }}
                onClose={() => setMenuOpen(false)}
                inputProps={{ id: 'dropdown-input' }}
                displayEmpty
                renderValue={getValue} // Use custom renderValue for multiselect
                IconComponent={() => (
                    <div style={{ marginRight: 14, alignItems: "center", justifyContent: "center", display: "flex", cursor: "pointer", padding: small ? '0px' : '8px' }}>
                        <Icon icon={menuOpen ? "up" : "down"} size={18} color={Colors.grayDark} onClick={()=>{toggleDropdown()}} />
                    </div>
                )}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            boxShadow: 'none',
                            borderRadius: '18px 18px 18px 18px',
                            border: `1px solid ${Colors.grayLight}`,
                            margin: "0px 0px 0px 0px",
                            padding: "0px 0px 0px 0px",
                            boxSizing: "border-box",
                            '& .MuiList-root': {
                                padding: '0px 0px 0px 0px',
                            },
                        }
                    },
                    sx: {
                        zIndex: 100,
                        left: "0px",
                    },
                }}
            >
                {options.map((option) => {
                    const isObject = typeof option === 'object';
                    const optionId = isObject ? option.id : option;
                    const optionValue = isObject ? option.value : option;

                    return (
                        <MenuItem key={optionId} value={optionId} sx={{
                            fontFamily: Fonts.outfitRegular.fontFamily,
                            fontWeight: Fonts.outfitRegular.fontWeight,
                            fontSize: small ? "14px" : "18px",
                            height: small ? "36px" : "48px",
                            color: Colors.grayDark,
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            },
                        }}>
                            {multiselect && (
                                <div style={{display: 'flex', alignItems: 'center', paddingRight: '4px' }}>
                                    <Checkbox checked={selectedValue.indexOf(optionValue) > -1} />
                                </div>
                            )}
                            <ListItemText primary={optionValue} disableTypography={true} />
                        </MenuItem>
                    );
                })}
            </Select>
        )}
            {/*<FormHelperText>{errorMessage}</FormHelperText>*/}
        </FormControl>
    );
};

export default Dropdown;
