import { FunctionComponent, useState } from "react";
import styled from "styled-components";
import Icon from './common/Icon';
import Colors from './common/Colors';
import Fonts from './common/Fonts';
import _ from 'lodash';

const Text1 = styled.div<{ color: string }>`
    font-size: 14px;
    line-height: 20px;
    font-weight: ${Fonts.outfitLight.fontWeight};
    font-family: ${Fonts.outfitLight.fontFamily};
    color: ${({ color }) => color};
    text-align: left;
`;

const ButtonToggleRoot = styled.button<{ backgroundColor?: string, borderColor?: string, iconTextColor?: string, }>`
    border: ${({ borderColor }) => `1px solid ${borderColor}`};
    padding: 6px 11px 6px 11px;
    color: ${({ iconTextColor }) => iconTextColor};
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 42px;
    width: auto;
    height: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
`;

export type ButtonToggleType = {
    text: string;
    icon?: string | null;
    onToggle: () => void; 
    isSelected?: boolean; 
    useExternalState?: boolean;
};

const ButtonToggle: FunctionComponent<ButtonToggleType> = ({
   text,
   icon = null,
   onToggle,
   isSelected = false,
   useExternalState = false,
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [internalSelected, setInternalSelected] = useState(false);

    const handleClick = _.debounce(() => {
        if (!useExternalState) {
            setInternalSelected(!internalSelected); // toggle internal state only if not using external state
        }
        onToggle();
    }, 100);
    const finalSelected = useExternalState ? isSelected : internalSelected;

    const onClickRaw = (e) => {
        e.preventDefault()
        handleClick();
    }

    let backgroundColor = Colors.white;
    let borderColor = Colors.grayLight;
    let iconTextColor = Colors.grayDark;

    if (isHovered) { 
        if (finalSelected) {
            backgroundColor = Colors.blueDark;
            borderColor = Colors.blueDark;
            iconTextColor = Colors.white;
        } else { 
            backgroundColor = Colors.paleBright;
            borderColor = Colors.blueDark;
            iconTextColor = Colors.grayDark;
        }
    } else if (finalSelected) {
        backgroundColor = Colors.blue;
        borderColor = Colors.blueDark;
        iconTextColor = Colors.white;
    }

    return (
        <ButtonToggleRoot
            onClick={onClickRaw}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            iconTextColor={iconTextColor}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {icon && <Icon onClick={handleClick} icon={icon} color={iconTextColor} size={18} />}
            {text && <Text1 color={iconTextColor} >{text}</Text1>}
        </ButtonToggleRoot>
    );
};

export default ButtonToggle;

