import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../components/basic/common/Colors";
import Fonts from "../components/basic/common/Fonts";
import Button from "../components/basic/Button";
import Dropdown from "../components/basic/Dropdown";
import Loading from "../components/basic/Loading";
import ErrorScreen from "../components/ErrorScreen";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useGetAssignment } from '../apollo/useAssignments';
import { useRenameStudent, useOverrideScore } from "../apollo/useStudentAssignments";
import Worksheet from "../components/Worksheet";
import _ from "lodash";

const ByStudentRoot = styled.div`
    height: 100svh;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    background-color: ${Colors.pale};
`;

const TopBarContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    z-index: 10;
    padding: 5px 5px;
    background-color: transparent;
    background-color: ${Colors.white};
    // border-bottom: 1px solid ${Colors.grayLight};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);
`;
const TopLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    padding: 0px 0px 0px 0px;
    gap: 5px;
`;
const TopCenter = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 260px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    margin: auto;
    gap: 5px;
    z-index: 18;

    @media (max-width: 400px) {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    }
`;
const TopRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: left;
    padding: 0px 0px 0px 0px;
    gap: 10px;
`;
const ScoreLarge = styled.div`
  position: relative;
  font-size: 32px;
  line-height: 32px;
  font-family: ${Fonts.outfitRegular.fontFamily};
  font-weight: ${Fonts.outfitRegular.fontWeight};
  color: ${Colors.grayMed};
`;

const ScrollableContainer = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    overflow-y: auto;
    gap: 10px;
    margin: 0px 0px 0px 0px;
    padding: 30px 30px;
`;

const ByStudent: FunctionComponent = () => {
    const navigate = useNavigate();
    const { assignmentId } = useParams<{ assignmentId: string }>();
    const { data, loading, error, refetch } = useGetAssignment(assignmentId);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const initialId = queryParams.get('studentAssignmentId') || queryParams.get('imageId') || '';
    const [selectedId, setSelectedId] = useState(initialId);
    const [allWorksheetIds, setAllWorksheetIds] = useState<string[]>([]);
    
    const { renameStudent } = useRenameStudent();
    const { overrideScore } = useOverrideScore();

    useEffect(() => {
        if (data && data.getAssignment && data.getAssignment.studentAssignments && data.getAssignment.images) {
            const scoredWorksheets = [...data.getAssignment.studentAssignments];
            const sorted = scoredWorksheets.sort((a, b) => {
                const regex = /(\d+)/;
                const partsA = a.studentName.split(regex);
                const partsB = b.studentName.split(regex);
                for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
                    if (partsA[i].toLowerCase() === partsB[i].toLowerCase()) continue;
                    if (!isNaN(Number(partsA[i])) && !isNaN(Number(partsB[i]))) {
                        return Number(partsA[i]) - Number(partsB[i]);
                    }
                    return partsA[i].toLowerCase().localeCompare(partsB[i].toLowerCase());
                }
                return partsA.length - partsB.length;
            });

            const keyWorksheets = data.getAssignment.images.filter(image => image.isAnswerKey);
            const unscoredWorksheets = data.getAssignment.images.filter(image => !image.studentAssignmentId && !image.isAnswerKey).sort((a, b) => a.uploadDate - b.uploadDate) || [];

            const allWorksheetIds = [...keyWorksheets.map(key => key.id), ...unscoredWorksheets.map(unscored => unscored.id), ...sorted.map(scored => scored.id)];
            setAllWorksheetIds(allWorksheetIds);
        }
    }, [data]);

    useEffect(() => {
        if (allWorksheetIds.length > 0) {
            const initialWorksheet = allWorksheetIds.find(id =>
                id === selectedId ||
                (selectedId === '' && (data?.getAssignment.images.find(image => image.id === id)?.studentAssignmentId === null && !data?.getAssignment.images.find(image => image.id === id)?.isAnswerKey))
            );
            if (initialWorksheet) {
                setSelectedId(initialWorksheet);
            } else {
                setSelectedId(allWorksheetIds[0]);
            }
        }
    }, [allWorksheetIds, selectedId, data]);

    if (loading) return <Loading></Loading>;
    if (error || !data) return <ErrorScreen></ErrorScreen>;

    const handleStudentChange = (selectedOption, direction?: 'next' | 'prev') => {
        let newIndex;
        if (selectedOption) {
            newIndex = allWorksheetIds.findIndex(id => id === selectedOption.id);
        } else if (direction === 'next') {
            const currentIndex = allWorksheetIds.findIndex(id => id === selectedId);
            newIndex = (currentIndex + 1) % allWorksheetIds.length;
        } else if (direction === 'prev') {
            const currentIndex = allWorksheetIds.findIndex(id => id === selectedId);
            newIndex = (currentIndex - 1 + allWorksheetIds.length) % allWorksheetIds.length;
        }
    
        const newSelectedId = allWorksheetIds[newIndex];
        if (newSelectedId) {
            setSelectedId(newSelectedId);
            const queryParam = data.getAssignment.images.find(image => image.id === newSelectedId)?.studentAssignmentId === null
                ? `imageId=${encodeURIComponent(newSelectedId)}`
                : `studentAssignmentId=${encodeURIComponent(newSelectedId)}`;
            navigate(`/by-student/${assignmentId}?${queryParam}`);
        }
    };

    const handleLeftClick = () => {
        handleStudentChange(null, 'prev');
    };
      
    const handleRightClick = () => {
        handleStudentChange(null, 'next');
    };
    
    const handleScoreChange = (_, scoreId, value) => {
        overrideScore(scoreId, value ? 1 : 0, "RIGHTWRONG").catch(console.error);
    };

    const handleRenameStudent = () => {
        const selectedWorksheet = data.getAssignment.studentAssignments.find(worksheet => worksheet.id === selectedId);
        if (selectedWorksheet) {
            const selectedName = selectedWorksheet.studentName;
            renameStudent(selectedId, selectedName).catch(console.error);
        }
    };

    const onInputChange = (newName) => {
        if (selectedId) {
            renameStudent(selectedId, newName).catch(console.error);
        }
    };

    const onCloseClick = () => {
        if (assignmentId) {
            navigate(`/review-scores/${assignmentId}`); 
        }
    };

    const studentNames = allWorksheetIds.map(id => {
        const studentAssignment = data.getAssignment.studentAssignments.find(worksheet => worksheet.id === id);
        if (studentAssignment) {
            return studentAssignment.studentName;
        } else {
            const image = data.getAssignment.images.find(image => image.id === id);
            if (image?.isAnswerKey) {
                const answerKeyIndex = data.getAssignment.images.filter(image => image.isAnswerKey).findIndex(key => key.id === id);
                return `ANSWER KEY ${answerKeyIndex + 1}`;
            } else {
                const unscoredIndex = data.getAssignment.images.filter(image => !image.studentAssignmentId && !image.isAnswerKey).findIndex(unscored => unscored.id === id);
                return `Unscored ${unscoredIndex + 1}`;
            }
        }
    });

    return (
        <ByStudentRoot>
            <TopBarContainer>
                <TopLeft>
                    <Button icon={'left'} onClick={handleLeftClick} color={'blue'} size={'medium'} />
                    <Button icon={'right'} onClick={handleRightClick} color={'blue'} size={'medium'} />
                </TopLeft>
                <TopCenter>
                    <Dropdown 
                        options={studentNames.map((name, index) => ({ value: name, id: allWorksheetIds[index] }))}
                        initialValue={selectedId && studentNames ? studentNames[allWorksheetIds.findIndex(id => id === selectedId)] : null}
                        onChange={(selectedOption) => handleStudentChange(selectedOption)}
                        rootInput={data.getAssignment.studentAssignments.some(worksheet => worksheet.id === selectedId)} 
                        onInputChange={data.getAssignment.studentAssignments.some(worksheet => worksheet.id === selectedId) ? onInputChange : undefined}
                        onInputBlur={data.getAssignment.studentAssignments.some(worksheet => worksheet.id === selectedId) ? handleRenameStudent : undefined}
                    />
                </TopCenter>
                <TopRight>
                {selectedId && data.getAssignment.studentAssignments.find(worksheet => worksheet.id === selectedId)?.scores?.length > 0 && (
                        <ScoreLarge>
                            {data.getAssignment.studentAssignments.find(worksheet => worksheet.id === selectedId)?.scores.filter(score => score.value > 0).length || 0}/{data.getAssignment.studentAssignments.find(worksheet => worksheet.id === selectedId)?.scores?.length || 0}
                        </ScoreLarge>
                    )}
                    <Button icon={'delete'} onClick={onCloseClick} color={'ghost'} size={'medium'} />
                </TopRight>
            </TopBarContainer>
            <ScrollableContainer>
                {selectedId && (
                    <Worksheet large={true}
                        key={selectedId}
                        rawImage={data.getAssignment.images.find(image => image.id === selectedId && !image.studentAssignmentId)}
                        answerKey={data.getAssignment.images.find(image => image.id === selectedId)?.isAnswerKey}
                        studentAssignment={data.getAssignment.studentAssignments.find(worksheet => worksheet.id === selectedId)}
                        failed={_.get(data, 'getAssignment.status') === 'ERROR' || data.getAssignment.images.find(image => image.id === selectedId)?.error === true}
                        onCorrectScore={handleScoreChange}
                    />
                )}
            </ScrollableContainer>
        </ByStudentRoot>
    );
};

export default ByStudent;