import React, { useEffect } from "react";
import { Routes, Route, useNavigationType, useLocation, } from "react-router-dom";
import SignUp from "./pages/SignUp";
import Processing from "./pages/Processing";
import Assignments from "./pages/Assignments";
import LogIn from "./pages/LogIn";
import Scan from "./pages/Scan";
import ReviewScores from "./pages/ReviewScores";
import Waitlist from "./pages/Waitlist";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import DeleteImages from "./pages/DeleteImages";
import AnswerKey from "./pages/AnswerKey";
import Unavailable from "./pages/Unavailable";
import Profile from "./pages/Profile";
import ByStudent from "./pages/ByStudent";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/processing":
        title = "";
        metaDescription = "";
        break;
      case "/assignments":
        title = "";
        metaDescription = "";
        break;
      case "/log-in":
        title = "";
        metaDescription = "";
        break;
      case "/scan":
        title = "";
        metaDescription = "";
        break;
      case "/review-scores":
        title = "";
        metaDescription = "";
        break;
      case "/waitlist":
        title = "";
        metaDescription = "";
        break;
      case "/forgot-password":
        title = "";
        metaDescription = "";
        break;
      case "/reset-password":
          title = "";
          metaDescription = "";
          break;
      case "/delete-images":
        title = "";
        metaDescription = "";
        break;
      case "/answer-key":
        title = "";
        metaDescription = "";
        break;
      case "/unavailable":
        title = "";
        metaDescription = "";
        break;
      case "/profile":
        title = "";
        metaDescription = "";
        break;
      case "/by-student":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<SignUp />} />
      <Route path="/processing/:assignmentId" element={<Processing />} />
      <Route path="/assignments" element={<Assignments />} />
      <Route path="/log-in" element={<LogIn />} />
      <Route path="/scan/:assignmentId" element={<Scan />} />
      <Route path="/scan/" element={<Scan />} />
      <Route path="/review-scores/:assignmentId" element={<ReviewScores />} />
      <Route path="/waitlist" element={<Waitlist />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/delete-images/:assignmentId" element={<DeleteImages />} />
      <Route path="/answer-key/:assignmentId" element={<AnswerKey />} />
      <Route path="/unavailable" element={<Unavailable />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/by-student/:assignmentId" element={<ByStudent />} />
    </Routes>
  );
}
export default App;
