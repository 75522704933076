import React, { FunctionComponent, useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Colors from './basic/common/Colors';
import Icon from './basic/common/Icon';
import styled from 'styled-components';

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 2px;
  z-index: 10;
`;

type Images = {
  img: string;
  name: string;
  id: string;
};

export type ImageGridType = {
  images: Images[];
  onUpdateSelectedImageIds: (ids: string[]) => void;
  color: 'red' | 'blue'; 
  initialSelectedImageIds?: string[]
};

const ImageGrid: FunctionComponent<ImageGridType> = ({ images, onUpdateSelectedImageIds, color, initialSelectedImageIds = [] }) => {

  const [selectedImages, setSelectedImages] = useState<string[]>(initialSelectedImageIds); // state to track selected images

  const handleSelectImage = (img: string) => {
    setSelectedImages((prevSelected) =>
      prevSelected.includes(img)
        ? prevSelected.filter((imageId) => imageId !== img) // deselect if already selected
        : [...prevSelected, img] // select if not already selected
    );
  };

  useEffect(() => {
    onUpdateSelectedImageIds(selectedImages);
  }, [selectedImages, onUpdateSelectedImageIds]);

  return (
    <ImageList sx={{ width: '100%', height: 'auto', marginTop: 0 }} cols={3} rowHeight={'auto'}>
      {images.map((item) => (
        <ImageListItem
        key={item.img}
        onClick={() => handleSelectImage(item.id)}
        sx={{ 
          cursor: 'pointer',
          position: 'relative',
          border: 2,
          borderColor: selectedImages.includes(item.id) ? (color === 'red' ? Colors.red : Colors.blue) : 'transparent',
          '&:hover': {
            borderColor: Colors.grayMed,
          },
          '&:hover, &:focus': {
            borderColor: selectedImages.includes(item.id) ? (color === 'red' ? Colors.redLight : Colors.blueLight) : Colors.grayMed,
          },
          '&:select': {
            borderColor: color === 'red' ? Colors.redDark : Colors.blueDark,
          },
        }}>
        {selectedImages.includes(item.id) && (
          <IconContainer><Icon icon={'check-fill'} size={24} color={color === 'red' ? Colors.red : Colors.blue} /></IconContainer>
        )}
        <img
          srcSet={`${item.img}`}
          src={`${item.img}`}
          alt={item.name}
          loading="lazy"
        />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default ImageGrid;