import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material";

import { ApolloProvider } from '@apollo/client';
import client from './apollo/apolloClient';
import { PostHogProvider} from 'posthog-js/react'

const options = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

const muiTheme = createTheme({
    components: {
        // Override styles for MUI's CssBaseline component
        MuiCssBaseline: {
            styleOverrides: `
        html, body {
          overscroll-behavior-y: none;
          height: 100%;
          margin: 0;
          padding: 0;
        }
      `,
        },
    },
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <ApolloProvider client={client}>
        <PostHogProvider
            apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
            options={options}
        >
          <BrowserRouter>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={muiTheme}>
                <CssBaseline />
                <App />
              </ThemeProvider>
            </StyledEngineProvider>
          </BrowserRouter>
        </PostHogProvider>
    </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
