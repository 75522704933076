import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Colors from "../components/basic/common/Colors";
import Fonts from "../components/basic/common/Fonts";
import ButtonFixed from "../components/basic/ButtonFixed";
import ButtonToggleGroup from "../components/basic/ButtonToggleGroup";
import { useNavigate } from 'react-router-dom';
import { useUpdateProfile, useGetUserInfo } from "../apollo/useAuth";
import { useGetAssignments } from '../apollo/useAssignments';

const ProfileRoot = styled.div`
    width: 100%;
    height: 100svh;
    position: relative;
    background-color: ${Colors.pale};
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const CenterContainer = styled.form`
    margin: 0;
    max-width: 342px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 20px;
    box-sizing: border-box;
    gap: 20px;
    // background-color: ${Colors.white};
    // border-radius: 10px;
`;

const Header2 = styled.h2`
    margin: 0;
    position: relative;
    font-size: 32px;
    line-height: 48px;
    font-weight: ${Fonts.outfitBold.fontWeight};
    font-family: ${Fonts.outfitBold.fontFamily};
    color: ${Colors.grayDark};
    text-align: center;
`;
const SubText = styled.div`
    font-size: 14px;
    line-height: 20px;
    font-weight: ${Fonts.outfitLight.fontWeight};
    font-family: ${Fonts.outfitLight.fontFamily};
    color: ${Colors.grayDark};
    text-align: center;
`;
const SelectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
`;
const SelectionText = styled.div`
    font-size: 18px;
    line-height: 24px;
    font-weight: ${Fonts.outfitRegular.fontWeight};
    font-family: ${Fonts.outfitRegular.fontFamily};
    color: ${Colors.grayDark};
    text-align: left;
  
`;
const ButtonContainer = styled.div`
    padding-top: 10px;
`;

const Profile = () => {
    const navigate = useNavigate();
  
    const [role, setRole] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [grades, setGrades] = useState([]);

    const { data: userInfoData, loading: userInfoLoading, error: userInfoError } = useGetUserInfo();
    const { data: assignmentData, refetch: refetchAssignments } = useGetAssignments();
    const { updateProfile } = useUpdateProfile();

    useEffect(() => {
      const updateHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      };
  
      window.addEventListener('resize', updateHeight);
      updateHeight(); 
  
      return () => window.removeEventListener('resize', updateHeight);
    }, []);

    useEffect(() => {
      if (userInfoData && userInfoData.getUserInfo) {
        const { role, subjects, grades } = userInfoData.getUserInfo;
        setRole(role ? [role] : []);
        setSubjects(subjects || []);
        setGrades(grades || []);
      }
    }, [userInfoData]);

    const onProfileConfirmClick = useCallback(async () => {
      // console.log('updateProfile called with:', role, subjects, grades);
      // console.log('userInfoData.getUserInfo:', userInfoData && userInfoData.getUserInfo);
      if (userInfoData && userInfoData.getUserInfo) {
        // console.log(role, subjects, grades);
        try {
          await updateProfile(role[0], subjects, grades); 
          navigateAfterLogin();
        } catch (error) {
          console.error('Error updating profile:', error);
        }
      }
    }, [role, subjects, grades, updateProfile]);

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          onProfileConfirmClick();
        }
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, [onProfileConfirmClick]);

    const navigateAfterLogin = async () => {
      try {
        const { data: refetchedAssignmentData } = await refetchAssignments();
        // console.log('Refetched assignment data:', refetchedAssignmentData);
    
        if ( refetchedAssignmentData && refetchedAssignmentData.getAssignments.length > 0 && refetchedAssignmentData.getAssignments.some((assignment) => assignment.status === 'DONE' )) {
          navigate("/assignments"); // if user has assignments, navigate to all assignments
        } else {
          navigate("/scan"); // if user doesn't have any assignments, navigate to scan
        }
      } catch (error) {
        console.error('Error refetching assignment data:', error);
      }
    };
  
    return (
        <ProfileRoot>
            <CenterContainer>
                <Header2>Welcome!<SubText>Please complete your profile.</SubText></Header2>
                <SelectionContainer>
                    <SelectionText>Role</SelectionText>
                    <ButtonToggleGroup options={['Teacher', 'Student', 'Parent', 'Other']} multiselect={false} onChange={(selected) => setRole(selected)} initialValue={role}/>
                </SelectionContainer>
                <SelectionContainer>
                    <SelectionText>Subjects</SelectionText>
                    <ButtonToggleGroup options={['Math', 'Science', 'ELA', 'Social Studies', 'Spanish', 'French', 'Other']} onChange={(selected) => setSubjects(selected)} initialValue={subjects}/>
                </SelectionContainer>
                <SelectionContainer>
                    <SelectionText>Grades</SelectionText>
                    <ButtonToggleGroup options={['K', '1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th', '12th', 'Undergraduate', 'Graduate']} onChange={(selected) => setGrades(selected)} initialValue={grades}/>
                </SelectionContainer>
                <ButtonContainer>
                    <ButtonFixed text="Confirm" onClick={onProfileConfirmClick} />
                </ButtonContainer>
            </CenterContainer>
        </ProfileRoot>
    );
  };
  
  export default Profile;