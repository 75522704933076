import React, { FunctionComponent, useCallback, useEffect, useRef, useState, useLayoutEffect } from "react";
import styled from "styled-components";
import Button from "../components/basic/Button";
import Icon from "../components/basic/common/Icon";
import { useNavigate } from "react-router-dom";
import { useGetAssignments } from "../apollo/useAssignments";
import { useGetUserInfo, useLogout } from '../apollo/useAuth';
import { useMemo } from 'react';
import Fonts from "../components/basic/common/Fonts";
import Colors from "../components/basic/common/Colors";
import Loading from "../components/basic/Loading";
import ErrorScreen from "../components/ErrorScreen";

const AssignmentsRoot = styled.div`
  width: 100%;
  height: 100svh;
  position: relative;
  background-color: ${Colors.pale};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding: 0px 0px 0px 0px;
  overflow-x: hidden;
  overflow-y: hidden;
`;

// header section with title and button
const HeaderFrame = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  max-width: 600px;
  padding: 0px 0px 0px 0px;
`;
const AssignmentHeader = styled.h1`
  margin: 20px 0px 0px 0px;
  position: relative;
  line-height: 48px;
  font-size: 32px;
  font-weight: ${Fonts.outfitBold.fontWeight};
  font-family: ${Fonts.outfitBold.fontFamily};
`;

// the whole scrollable section
const ScrollableContent = styled.div`
  overflow-y: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0px 10px 0px 10px;
`;

// empty assignment
const EmptyAssignment = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 76vh;
`;
const EmptyHeaderText = styled.div`
  color: ${Colors.grayDark};
  font-weight: ${Fonts.outfitRegular.fontWeight};
  font-family: ${Fonts.outfitRegular.fontFamily};
  font-size: 20px;
  line-height: 20px;
  padding: 20px 0px 20px 0px;
`;
const EmptyIllustration = styled.img`
`;
const EmptyBodyText = styled.div`
  color: ${Colors.grayDark};
  font-weight: ${Fonts.outfitRegular.fontWeight};
  font-family: ${Fonts.outfitRegular.fontFamily};
  font-size: 16px;
  line-height: 20px;
  padding: 20px 0px 20px 0px;
`;

// date labels
const DateContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 0px 10px 0px;
  max-width: 600px;
  margin: auto;
`;
const DateLabel = styled.div`
  color: ${Colors.grayDark};
  font-weight: ${Fonts.outfitLight.fontWeight};
  font-family: ${Fonts.outfitLight.fontFamily};
  font-size: 18px;
  line-height: 18px;
  flex: 1;
`;

// individual assignments
const AssignmentContainer = styled.div`
  align-self: stretch;
  flex-direction: row;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
`;
const Assignment = styled.div`
  border-radius: 4px;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.grayLight};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px;
  gap: 10px;
  max-width: 600px;
  margin: auto;
  cursor: pointer;
  overflow: hidden;

  &:hover, &:focus { 
    border: 1px solid ${Colors.grayDark};
   }

  &:active {
    border: 2px solid ${Colors.blue};
  }
`;
const AssignmentInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 0px 0px;
`;

// left: icon and name
const AssignmentLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding: 10px 0px 0px 10px;
  flex: 1;
  max-width: calc(100% - 62px);
`;
const AssignmentIcon = styled.div`
  width: 44px;
  height: 44px;
`;
const AssignmentName = styled.div`
  border: none;
  outline: none;
  background-color: transparent;
  color: ${Colors.grayDark};
  font-weight: ${Fonts.outfitRegular.fontWeight};
  font-family: ${Fonts.outfitRegular.fontFamily};
  font-size: 18px;
  line-height: 20px;
  z-index: 1;
  cursor: pointer;

  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 10px;
  min-width: 0;
`;

// right: data readout
const AssignmentRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 0px 0px;
    flex: 0 0 54px;
    min-width: 54px;
`;
const AssignmentCount = styled.div`
    font-size: 32px;
    line-height: 32px;
    font-family: ${Fonts.outfitRegular.fontFamily};
    font-weight: ${Fonts.outfitRegular.fontWeight};
`;
const AssignmentCountLabel = styled.div`
    font-size: 8px;
    line-height: 16px;
    font-family: ${Fonts.outfitRegular.fontFamily};
    font-weight: ${Fonts.outfitRegular.fontWeight};
`;

// progress bar
const ProgressBarFrame = styled.div`
  align-self: stretch;
  position: relative;
  z-index: 2;
  height: 2px;
`;
const ProgressBarBG = styled.div`
  position: absolute;
  background-color: ${Colors.grayLight};
  z-index: 2;
  height: 2px;
  width: 100%;
`;
const ProgressBarFill = styled.div`
  position: absolute;
  background-color: ${Colors.grayMed};
  z-index: 2;
  height: 2px;
  width: 20%;
`;

// footer
const LogOutContainer = styled.div`
  position: ${({ isPinned }) => (isPinned ? 'fixed' : 'relative')};
  bottom: ${({ isPinned }) => (isPinned ? '0' : 'auto')};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0px 0px 0px;
  background-color: ${Colors.pale};
`;
const LogOutInner = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${Colors.grayLight};
  padding: 10px 0px 10px 0px;
`;
const LogOutLeft = styled.div`
  color: ${Colors.grayDark};
  font-weight: ${Fonts.outfitRegular.fontWeight};
  font-family: ${Fonts.outfitRegular.fontFamily};
  font-size: 14px;
  line-height: 22px;
  margin-left: 10px;
`;
const LogOutRight = styled.div`
  margin-right: 10px;
`;


type AssignmentType = {
    id: string;
    name: string;
    createdAt: string;
};

const AssignmentCard: FunctionComponent = ({ assignmentData: AssignmentType }) => {
  return <div>This is assignment data</div>
}

const Assignments: FunctionComponent = () => {
  const navigate = useNavigate();
  const { data, loading, error, refetch } = useGetAssignments();
  const { data: userData } = useGetUserInfo();

  const [isPinned, setIsPinned] = useState(false);
  const headerRef = useRef(null);
  const assignmentsRef = useRef(null);
  const footerRef = useRef(null);

  const { logout } = useLogout(); // Invoke the useLogin hook

  useEffect(() => {
    refetch();
  }, [refetch]);

  useLayoutEffect(() => {
    const adjustLayout = () => {
      const headerHeight = headerRef.current ? headerRef.current.offsetHeight : 0;
      const assignmentsHeight = assignmentsRef.current ? assignmentsRef.current.offsetHeight : 0;
      const footerHeight = footerRef.current ? footerRef.current.offsetHeight : 0;

      const totalContentHeight = headerHeight + assignmentsHeight + footerHeight;
      const viewportHeight = window.innerHeight;
      setIsPinned(totalContentHeight < viewportHeight);
      // console.log('viewport: ' + viewportHeight);
      // console.log('content: ' + totalContentHeight);
    };
    adjustLayout();
    window.addEventListener('resize', adjustLayout);
    return () => window.removeEventListener('resize', adjustLayout);
  }, [data]);
  // useEffect(() => { console.log('isPinned: ' + isPinned); }, []);

  const onNewAssignmentClick = useCallback(() => {
    navigate("/scan");
  }, [navigate]);

  const onLoadAssignmentClick = useCallback((assignmentId: string) => {
    navigate(`/review-scores/${assignmentId}`); // Adjust the navigation path as needed
  }, [navigate]);

  const onLogoutClick = async () => {
    await logout();
    navigate("/log-in");
  };

  const sortedAssignmentsWithDateLabels = useMemo(() => {
    if (!data || !data.getAssignments) return [];
  
    // filter out assignments with 0 imageCount first, to handle failed uploads
    const filteredAssignments = data.getAssignments.filter((assignment: AssignmentType) => assignment.imageCount !== 0);
  
    // sort the filtered assignments by date
    const sortedAssignments = filteredAssignments.sort((a: AssignmentType, b: AssignmentType) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  
    return sortedAssignments.reduce((acc: any[], assignment: AssignmentType, index: number) => {
      const assignmentDate = formatDate(assignment.createdAt);
      let showDateLabel = false;
  
      // check if it's the first assignment or if the date is different from the previous assignment
      if (index === 0 || formatDate(acc[index - 1]?.createdAt) !== assignmentDate) {
        showDateLabel = true;
      }
  
      acc.push({...assignment, showDateLabel});
      // console.log("Assignment Count: " + sortedAssignments.length);
      return acc;
    }, []);
  }, [data]);

  if (loading && !sortedAssignmentsWithDateLabels.length) return <Loading></Loading>;
  if (error) return <ErrorScreen></ErrorScreen>;

  function formatDate(isoDate) {
    const date = new Date(isoDate);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    // normalize the dates to remove the time portion
    const dateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const todayOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const yesterdayOnly = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());

    if (dateOnly.getTime() === todayOnly.getTime()) {
      return "TODAY";
    } else if (dateOnly.getTime() === yesterdayOnly.getTime()) {
      return "YESTERDAY";
    } else {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options).toUpperCase();
      return formattedDate;
    }
  }

  // status = OPEN, IN_PROCESS, DONE 

  function ProgressBar({ status }) {
    return null;
    if (status === 'DONE') { return null; } // don't render anything if status is 'DONE'
    return ( 
        <ProgressBarBG><ProgressBarFill></ProgressBarFill></ProgressBarBG> // otherwise include the progress bar
    );}

  return (
      <AssignmentsRoot>
          <HeaderFrame ref={headerRef}>
              <AssignmentHeader>Assignments</AssignmentHeader>
              <Button color={'blue'} icon={'plus'} onClick={onNewAssignmentClick}/>
          </HeaderFrame>
              <ScrollableContent ref={assignmentsRef}>
              {sortedAssignmentsWithDateLabels.length === 0 ? (
                <EmptyAssignment> 
                  <EmptyHeaderText>Get started!</EmptyHeaderText>
                  <EmptyIllustration src={`${process.env.PUBLIC_URL}/assignments-illustration.svg`} />
                  <EmptyBodyText>Create your first assignment with the + button.</EmptyBodyText>
                </EmptyAssignment>
                ) : (
                sortedAssignmentsWithDateLabels.map((assignment: AssignmentType & { showDateLabel: boolean }) => (
                <AssignmentContainer key={assignment.id}>
                  {assignment.showDateLabel && (
                    <DateContainer>
                      <DateLabel>{formatDate(assignment.createdAt)}</DateLabel>
                    </DateContainer>)}
                    <Assignment onClick={() => onLoadAssignmentClick(assignment.id)}>
                        <AssignmentInner>
                            <AssignmentLeft>
                              <AssignmentIcon> 
                                <Icon 
                                  size={44} 
                                  icon={assignment.status === 'ERROR' ? 'exclamation' : (assignment.status === 'DONE' || assignment.status === 'IN_PROCESS' ? 'page' : 'page-outline')} 
                                  color={assignment.status === 'ERROR' ? Colors.red : (assignment.status !== 'DONE' ? Colors.grayLight : Colors.grayMed)} 
                                />
                                </AssignmentIcon>
                              <AssignmentName>{assignment.name}</AssignmentName>
                            </AssignmentLeft>
                              {/* <div style={{ width: '14px' }}>{assignment.answerKeyImageCount > 0 && (<Icon size={14} icon={'key'} color={Colors.grayMed}/>)}</div>  */}
                            <AssignmentRight>
                                <AssignmentCount>{assignment.status != 'DONE' ? assignment.imageCount - assignment.answerKeyImageCount : assignment.studentAssignmentCount - assignment.answerKeyImageCount}</AssignmentCount>
                                <AssignmentCountLabel>{assignment.status === 'ERROR' ? 'ERROR' : (assignment.status !== 'DONE' ? (assignment.status === 'IN_PROCESS' ? 'SCORING' : 'UPLOADED') : `SCORED${assignment.imageCount > assignment.studentAssignmentCount ? '+' : ''}`)}</AssignmentCountLabel>
                            </AssignmentRight>
                        </AssignmentInner> 
                        <ProgressBarFrame> 
                          {/* <ProgressBar status={assignment.status} /> */} 
                        </ProgressBarFrame>
                    </Assignment>
                </AssignmentContainer>
              )))}
              <LogOutContainer ref={footerRef} isPinned={isPinned}>
                <LogOutInner>
                  <LogOutLeft>{userData?.getUserInfo?.email ?? 'email'}</LogOutLeft>
                  <LogOutRight><Button color={'white'} text={'Log Out'} icon={'logout'} onClick={onLogoutClick}/></LogOutRight>
                </LogOutInner>
              </LogOutContainer>
          </ScrollableContent>
      </AssignmentsRoot>
    );
};

export default Assignments;

