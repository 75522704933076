import React, { FunctionComponent, useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import Colors from './common/Colors';
import Fonts from './common/Fonts';
import Icon from "./common/Icon";
import Button from './Button';
import _ from 'lodash';

const getBGColor = (colorName) => {
    const colorMapping = {
        red: Colors.redPale, 
        yellow: Colors.yellowPale,
        green: Colors.greenPale, 
    };
    return colorMapping[colorName] || Colors.greenPale; // default 
};

const getAccentColor = (colorName) => {
    const accentColorMapping = {
        red: Colors.red, 
        yellow: Colors.yellow, 
        green: Colors.green, 
    };
    return accentColorMapping[colorName] || Colors.green; // default 
};

const getIconType = (colorName) => {
    const iconMapping = {
        red: 'exclamation',
        yellow: 'exclamation',
        green: 'info',
    };
    return iconMapping[colorName] || 'info'; // default
};

const MessageBackground = styled.div`
    background-color: ${({ color }) => getBGColor(color)};
    width: 355px;
    max-width: calc(100% - 20px);
    border-radius: 14px; 
    position: relative;
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: center; 
    padding: 10px 14px 10px 14px;
    margin: auto;
    box-shadow: -2px 6px 6px rgba(0,0,0,0.1);
    z-index: 100;
    gap: 12px;
    text-align: left;
`;

const IconContainer = styled.div`
    border-radius: 50%;
    background-color: ${({ color }) => getAccentColor(color)};
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;  
    padding: 10px 10px 10px 10px;
    margin: auto;
`;

const TextContainer = styled.div`
    width: 100%;  
    display: flex;
    flex-direction: column; 
    align-items: flex-start;
    justify-content: center; 
    gap: 4px;
    margin: auto; 
`;
const TitleText = styled.div`   
    font-weight: ${Fonts.outfitBold.fontWeight};
    font-family: ${Fonts.outfitBold.fontFamily};
    font-size: 14px;
    line-height: 22px;
    color: ${Colors.grayMed};
`;
const BodyText = styled.div`
    font-weight: ${Fonts.outfitLight.fontWeight};
    font-family: ${Fonts.outfitLight.fontFamily};
    font-size: 14px;
    line-height: 18px; 
    color: ${Colors.grayMed};
    white-space: pre-wrap;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;  
    margin: auto;
`;

export type MessageAlertType = {
    titleText: string;
    bodyText: string | string[];
    color: string;
    onClose: () => void;
    includeClose: boolean;
};

const modalRef = React.createRef<HTMLDivElement>();

const MessageAlert: FunctionComponent<MessageAlertType> = ({
    titleText,
    bodyText,
    color,
    onClose,
    includeClose,
 }) => {

    return ( 
        <MessageBackground color={color} ref={modalRef}>
            <IconContainer color={color}>
                <Icon icon={getIconType(color)} size={28} color={Colors.white} onClick={() => {}} />
            </IconContainer>
            <TextContainer>
                <TitleText>{titleText}</TitleText>
                {Array.isArray(bodyText) ? (
                bodyText.map((text, index) => (
                    <BodyText key={index}>{text}</BodyText>
                ))
                ) : (
                <BodyText>{bodyText}</BodyText>
                )}
            </TextContainer>
            {includeClose !== false && (
            <ButtonContainer>
                <Button icon={'delete'} color={'ghost'} onClick={onClose} />
            </ButtonContainer>)}
        </MessageBackground>
    ); }

export default MessageAlert;