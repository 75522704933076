// fonts.js

const Fonts = {
    outfitRegular: {
        fontFamily: 'Outfit',
        fontWeight: 400,
    },
    outfitMedium: {
        fontFamily: 'Outfit',
        fontWeight: 500,
    },
    outfitBold: {
        fontFamily: 'Outfit',
        fontWeight: 700,
    },
    outfitLight: {
        fontFamily: 'Outfit',
        fontWeight: 300,
    },
};

export default Fonts;
