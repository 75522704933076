import React from 'react';
import styled from 'styled-components';
import Colors from './basic/common/Colors';

const PositionedWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: no-wrap;
  width: 100%;
  max-width: 100%;
  min-height: 50px;
  padding: 5px 5px;
  gap: 5px;
  margin: 0;
`;

const LeftAligned = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  z-index: 20;
`;

const CenterAligned = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-grow: 4;
  flex-shrink: 4;
  flex-basis: 0;
  align-items: center;
  justify-content: center;
  gap: 5px;
  z-index: 18;

  @media (max-width: 400px) {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
`;

const RightAligned = styled.div`
  position: relative;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  z-index: 19;
`;

const PositionedLayout = ({
                              leftComponent,
                              centerComponent,
                              rightComponent,
                          }) => (
    <PositionedWrapper>
        <LeftAligned>{leftComponent}</LeftAligned>
        <CenterAligned>{centerComponent}</CenterAligned>
        <RightAligned>{rightComponent}</RightAligned>
    </PositionedWrapper>
);

export default PositionedLayout;
