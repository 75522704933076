import {FunctionComponent, useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import Input from "../components/basic/Input";
import Dropdown from "../components/basic/Dropdown";
import ButtonFixed from "../components/basic/ButtonFixed";
import { useNavigate } from "react-router-dom";
import ButtonLink from "../components/basic/ButtonLink";
import { useRegister, useGetUserInfo } from "../apollo/useAuth";
import Colors from "../components/basic/common/Colors";
import Fonts from "../components/basic/common/Fonts";
import { useGetAssignments } from "../apollo/useAssignments";

const SignUpRoot = styled.div`
  width: 100%;
  height: 100svh;
  position: relative;
  background-color: ${Colors.pale};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  color: ${Colors.grayDark};
  font-family: ${Fonts.outfitRegular.fontFamily};
`;
const SignUpParent = styled.div`
  margin: 0;
  width: 100%;
  max-width: 320px;
  height: calc(100svh - 42px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  box-sizing: border-box;
  gap: 0px;
  z-index: 1;
  background-color: ${Colors.pale};
`;

const SignUp1 = styled.h2`
  margin: 0;
  position: relative;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  font-family: ${Fonts.outfitRegular.fontFamily};
  color: ${Colors.grayDark};
  text-align: left;
  padding-bottom: 26px;
`;
const Input1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`;
const DropDown1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;
const FooterText = styled.div`
  position: relative;
  line-height: 20px;
  font-weight: 300;
  cursor: text;
`;
const ButtonContainer = styled.div`
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
const BottomFixed = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  bottom: 0px;
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 10px 10px 10px 10px;
  max-width: 400px;
`;
const BottomContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const SignUp: FunctionComponent = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [grades, setGrades] = useState([]);

  const [error, setError] = useState(null);

  const { register, registerData, registerLoading, registerError } = useRegister();
  const { data: userInfoData, loading: userInfoLoading, error: userInfoError } = useGetUserInfo();

  const { data: assignmentData, refetch: refetchAssignments } = useGetAssignments();

  const onRegisterClick = useCallback(() => {
    if (name.length < 1) {
      setError('name');
      return;
    }
    if (!email.includes('@') || !email.includes('.')) {
      setError('email')
      return;
    }
    if (password.length < 8) {
      setError('password');
      return;
    }
    setError(null);
    register(name, (email || "").toLowerCase(), password, subjects, grades);
  }, [name, email, password, subjects, grades, register]);

  useEffect(() => {
    if (registerData && registerData.register.token) {
      navigate("/profile"); 
    }
  }, [registerData, navigate]);

  useEffect(() => {
    if (userInfoData && userInfoData.getUserInfo) {
      // console.log('user info: ' + userInfoData.getUserInfo);
      navigateAfterLogin();
    }
  }, [userInfoData, navigate]);

  const navigateAfterLogin = async () => {
    try {
      const { data: refetchedAssignmentData } = await refetchAssignments();
      // console.log('Refetched assignment data:', refetchedAssignmentData);
      if ( userInfoData && userInfoData.getUserInfo ) {
        const { role, subjects, grades } = userInfoData.getUserInfo;
        // console.log('role: ' + role); console.log('subjects: ' + subjects); console.log('grades: ' + grades);
  
        if ( !role || !subjects || !grades || role.length === 0 || subjects.length === 0 || grades.length === 0 ) {
          navigate("/profile"); // if user hasn't set their profile, first navigate to profile
          return;
        }
      }
  
      if ( refetchedAssignmentData && refetchedAssignmentData.getAssignments.length > 0 && refetchedAssignmentData.getAssignments.some((assignment) => assignment.status === 'DONE' )) {
        navigate("/assignments"); // if user has assignments, navigate to all assignments
      } else {
        navigate("/scan"); // if user doesn't have any assignments, navigate to scan
      }
    } catch (error) {
      console.error('Error refetching assignment data:', error);
    }
  };

  useEffect(() => {
    if (registerError) {
      // console.error('Registration error:', registerError);
      setError('email');
    }
  }, [registerError]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        onRegisterClick(); // register when user presses enter key
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onRegisterClick]);

  const onFooterTextClick = () => {
    navigate("/log-in");
  };

  return (
    <SignUpRoot>
      <SignUpParent>
        <SignUp1>Sign Up</SignUp1>
        <Input1>
          <Input
              placeholder="Name"
              icon={'person'}
              onChange={(x) => setName(x)}
              errorMessage={error == 'name' ? "Please enter your name." : null}
          />
          <Input
              placeholder="Email"
              icon={'mail'}
              onChange={(x) => setEmail(x)}
              errorMessage={error == 'email' ? "Unable to register with this email address." : null}
          />
          <Input
              placeholder="Password"
              icon={'lock'}
              type={"password"}
              onChange={(x) => setPassword(x)}
              errorMessage={error == 'password' ? "Your password must be at least 8 characters." : null}
          />
        </Input1>
        {/* <DropDown1>
          <Dropdown
              placeholder={"Subjects"}
              options={['Math', 'Science', 'ELA', 'Social Studies', 'Spanish', 'French', 'Other']}
              multiselect
              onChange={(selected) => setSubjects(selected)}
          />
          <Dropdown
              placeholder={"Grades"}
              options={['K', '1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th', '12th', 'Undergraduate', 'Graduate']}
              multiselect
              onChange={(selected) => setGrades(selected)}
          />
        </DropDown1> */}
      <ButtonContainer>
        <ButtonFixed text="Sign Up" onClick={onRegisterClick} />
      </ButtonContainer>
      </SignUpParent>
      <BottomFixed >
        <BottomContainer>
          <FooterText>
            Already have an account?
          </FooterText>
          <ButtonLink text="Log In" onClick={onFooterTextClick} />
        </BottomContainer>
      </BottomFixed>
    </SignUpRoot>
  );
};

export default SignUp;
