import React from 'react';
import { Checkbox as MUICheckbox, styled } from '@mui/material';
import Colors from './common/Colors';

const Checkbox = styled(({ checked = false, onChange = () => {}, ...props }) => (
    <MUICheckbox
        checked={checked}
        onChange={onChange}
        {...props}
        sx={{
            color: Colors.grayDark,
            padding: 0, // Remove padding if any,
            '&.Mui-checked': {
                color: Colors.blue,
            },
            '& .MuiSvgIcon-root': { // Adjust the icon style directly
                fontSize: 16, // Set the icon size to 16x16 pixels
                // Remove any additional color properties that might conflict
            },
            '&.Mui-checked .MuiIconButton-label::before': {
                border: `1px solid ${Colors.blue}`, // Border color when checked
            },
            '&:hover': {
                backgroundColor: 'transparent', // Remove default hover background color
                '& .MuiIconButton-label::before': {
                    borderColor: `${Colors.grayDark}`, // Border color on hover
                }
            },
            '&.Mui-checked:hover .MuiIconButton-label::before': {
                borderColor: `${Colors.blue}`, // Border color on hover when checked
            },
        }}
    />
))(({ theme }) => ({})); // Remove the default MUI styling

export default Checkbox;