import React, { FunctionComponent, useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import Button from "../components/basic/Button";
import Fonts from "../components/basic/common/Fonts";
import Colors from "../components/basic/common/Colors";
import ImageGrid from '../components/ImageGrid';
import MessageAlert from "../components/basic/MessageAlert";
import { useGetAssignment, useSetAnswerKeyImages } from '../apollo/useAssignments';
import { useNavigate, useParams } from 'react-router-dom';

const AnswerKeyRoot = styled.div`
    width: 100%;
    height: 100svh;
    position: relative;
    background-color: ${Colors.pale};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    padding: 0px 0px 0px 0px;
    overflow-x: hidden;
    overflow-y: hidden;
`;

const TopBarContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    z-index: 10;
    padding: 5px 5px 10px 5px;
`;
const TopLeft = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    padding: 0px 0px 0px 0px;
`;
const TopCenter = styled.div`
    flex: 2;
    display: flex;
    justify-content: center;
    font-weight: ${Fonts.outfitRegular.fontWeight};
    font-family: ${Fonts.outfitRegular.fontFamily};
    font-size: 16px;
    line-height: 20px;
`;
const TopRight = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding: 0px 0px 0px 0px;
`;

const ScrollableContainer = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    overflow-y: auto;
    gap: 10px;
    margin: 0px 0px 0px 0px;
    padding: 0px 10px 10px 10px;
`;

const AnswerKey: FunctionComponent = () => {
    const navigate = useNavigate();

    const { assignmentId } = useParams();

    const { data, loading, error, refetch } = useGetAssignment(assignmentId);
    const [imagesList, setImages] = useState([]);
    const [selectedImageIds, setSelectedImageIds] = useState<string[]>([]);
    const { setAnswerKeyImages } = useSetAnswerKeyImages(); // Use the mutation hook

    useEffect(() => {
        if (data && data.getAssignment) {
            const unassignedImages = data.getAssignment.images.filter(image => !image.studentAssignmentId); // filter out scored images
            const formattedImages = unassignedImages.map(image => ({
                img: image.url,
                name: 'uploaded image',
                id: image.id
            }));
            setImages(formattedImages);

            // Set initial selected image IDs based on isAnswerKey
            const initialSelectedIds = unassignedImages.filter(image => image.isAnswerKey).map(image => image.id);
            setSelectedImageIds(initialSelectedIds);
        }
    }, [data]);

    const handleUpdateSelectedImageIds = useCallback((ids: string[]) => {
        setSelectedImageIds(ids);
    }, []);

    const onBackClick = useCallback(() => {
        let navigationState = {};
        if (imagesList.length > 0) {
            const lastImageThumbnail = imagesList[imagesList.length - 1]?.img;
            const numImages = imagesList.length;
            navigationState = { lastImageThumbnail, numImages }; // pass the last image's URL, and the number of images
        }
        navigate(`/scan/${assignmentId}`, { state: navigationState });
    }, [navigate, selectedImageIds, imagesList, assignmentId]);

    const onConfirmClick = useCallback(async () => {
        let navigationState = {};
        if (imagesList.length > 0) {
            const lastImageThumbnail = imagesList[imagesList.length - 1]?.img;
            const numImages = imagesList.length;
            navigationState = { lastImageThumbnail, numImages }; // pass the last image's URL, and the number of images
            await setAnswerKeyImages(assignmentId, selectedImageIds);
        }
        navigate(`/scan/${assignmentId}`, { state: navigationState });
    }, [navigate, selectedImageIds, setAnswerKeyImages, imagesList, assignmentId]);

    return (
        <AnswerKeyRoot>
            <TopBarContainer>
                <TopLeft><Button color={'blue'} icon="left" onClick={onBackClick}/></TopLeft>
                {imagesList.length > 0 && <TopCenter>Select answer key.</TopCenter>}
                <TopRight>
                    <Button color={'blue'}
                        icon="key" 
                        text={"Confirm"} 
                        onClick={onConfirmClick} 
                        disabled={selectedImageIds.length === 0 ? true : undefined}/>
                </TopRight>
            </TopBarContainer>
            <ScrollableContainer>
            {imagesList.length === 0 ? (
                <MessageAlert 
                    titleText={'Answer Key'} 
                    bodyText={[
                        'First scan or upload images to select an answer key.',
                        'Format your answer key the same way as a student assignment.'
                    ]}
                    color='yellow'
                    onClose={() => {}}
                    includeClose={false}/>
                ) : (
                <ImageGrid images={imagesList} onUpdateSelectedImageIds={handleUpdateSelectedImageIds} color={'blue'} initialSelectedImageIds={selectedImageIds} />
                )}
            </ScrollableContainer>
        </AnswerKeyRoot>
    );
};

export default AnswerKey;