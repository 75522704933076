import React, { useEffect, useRef, useImperativeHandle, forwardRef, useState } from 'react';
import jscanify from '../utils/jscanify';
import { useImageUpload } from '../apollo/useImageUpload';
import Button from "../components/basic/Button";

type ScannerProps = {
  onScan?: (canvas: HTMLCanvasElement) => void;
  imageUploaded?: (imageId: string, assignmentId?: string | null) => void;
  assignmentId?: string | null;
  onAspectRatioChange?: (aspectRatio: number) => void;
  onNewThumbnail?: (string) => void;
  onNewBGFrame?: (string) => void;
};

const FRAME_SKIP_COUNT = 60;

const Scanner: React.FC<ScannerProps> = forwardRef((props, ref) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const resultRef = useRef<HTMLCanvasElement>(null);
  const { uploadImage, loading } = useImageUpload();
  const [started, setStarted] = useState(false);
  const [showStartButton, setShowStartButton] = useState(false);
  const [cameraReady, setCameraReady] = useState(false);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  let frameCounter = 0;

  useEffect(() => {
    // Check if uploadLoadingUpdated is not null before calling
    if (props.uploadLoadingUpdated != null) {
      props.uploadLoadingUpdated(loading);
    }
  }, [loading, props.uploadLoadingUpdated])

  useEffect(() => {
    // Check if uploadLoadingUpdated is not null before calling
    if (props.onCameraStart != null && started) {
      props.onCameraStart();
    }
  }, [started, props.onCameraStart])

  useEffect(() => {
    console.log('Scanner component mounted.');
    const scanner = new jscanify();
    console.log('jscanify instance created.');

    let streamVar: MediaStream | null = null;

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      console.log('Requesting camera access...');
      navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
          .then((stream) => {
            streamVar = stream;
            console.log('Camera access granted.');
            if (videoRef.current) {
              (async () => {
                try {
                  videoRef.current.srcObject = stream;
                  videoRef.current.muted = true;
                  if (started) {
                    await videoRef.current.play();
                  }
                  else {
                    await videoRef.current.play();
                  }
                  console.log('Video stream started.');
                  setStarted(true);
                } catch (err) {
                  console.log('Error playing video:', err);
                  setShowStartButton(true);
                  //window.alert("Error playing video: " + err.message);
                }
              })()
            }
          })
          .catch((err) => {
            console.error("Error accessing camera:", err);
            setShowStartButton(true);
            //window.alert("Error accessing camera: " + err.message); // Alerting the user about camera access error
          });

      const processVideo = () => {
        // console.log('Processing video...');
        try {
          if (videoRef.current && canvasRef.current && resultRef.current) {
            const canvasCtx = canvasRef.current.getContext("2d");
            const resultCtx = resultRef.current.getContext("2d");
            if (canvasCtx && resultCtx) {
              canvasCtx.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
              // console.log('Video frame drawn on canvas.');
              setCameraReady(true);
              let resultCanvas = null;
	      
              // This likes to crash - fine for a frame or two but need to always be ready to handle
              try {
		        resultCanvas = scanner.highlightPaper(canvasRef.current, {});
              } catch (err) {
                  console.log(err);
              }
              if (resultCanvas && false) { // Check if resultCanvas is not null (disable drawing bounds)
                // console.log('Paper highlighted.');
                resultCtx.drawImage(resultCanvas, 0, 0);
              } else {
                 resultCtx.drawImage(canvasRef.current, 0, 0);
              }
              requestAnimationFrame(processVideo);
            }
          }
        } catch (err) {
          console.log('Frame error:', err);
          //window.alert("Frame error: " + err.message);
        }

        frameCounter++;
        if (frameCounter >= FRAME_SKIP_COUNT) {
          frameCounter = 0; // reset counter
          if (canvasRef.current && started) {
            const currentFrame = canvasRef.current.toDataURL(); // Get the current frame as a data URL
            props.onNewBGFrame?.(currentFrame); // Call the onNewBGFrame callback with the current frame
          }
        }

      };

      if (videoRef.current) {
        videoRef.current.onloadedmetadata = () => {
          if (videoRef.current && canvasRef.current && resultRef.current) {
            const aspectRatio = videoRef.current.videoWidth / videoRef.current.videoHeight;
            props.onAspectRatioChange(aspectRatio);
            canvasRef.current.width = videoRef.current.videoWidth;
            canvasRef.current.height = videoRef.current.videoHeight;
            resultRef.current.width = videoRef.current.videoWidth;
            resultRef.current.height = videoRef.current.videoHeight;
            console.log('Canvas dimensions set based on video metadata.');
            processVideo();
          }
        };
      }
    } else if (!started) {
      console.log('Camera not started.');
      setShowStartButton(true);
    } else {
      console.log('MediaDevices API or getUserMedia not supported.');
      setShowStartButton(true);
      //window.alert('Camera access is not supported by your browser.'); // Alert for unsupported browser
    }

    return () => {
      // Cleanup function to stop the camera stream
      // TODO: Keep open when uploading?
      if (streamVar) {
        streamVar.getTracks().forEach(track => track.stop());
        console.log('Camera stream stopped.');
      }
    };
  }, [started]);

  const captureImage = async () => {
    if (!cameraReady) {
      console.log("Camera not yet ready");
      return;
    }
    if (canvasRef.current) {
      canvasRef.current.toBlob(async (blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          // Call onNewThumbnail with the base64 string of the image
          props.onNewThumbnail?.(reader.result.toString());
          // Proceed with the upload
          uploadImage(props.assignmentId, blob).then(uploadResult => {
            if (uploadResult.success) {
              const updatedAssignmentId = uploadResult.assignmentId ? uploadResult.assignmentId : props.assignmentId;
              props.imageUploaded?.(uploadResult.imageId, updatedAssignmentId);
            } else {
              console.error('Image upload failed:', uploadResult.message);
            }
          });
        };
        if (blob) {
          reader.readAsDataURL(blob);
        }
      }, 'image/png');
    }
  };

  useImperativeHandle(ref, () => ({
    captureAndUpload: () => {
      captureImage();
    },
  }));

  const startCamera = () => setStarted(true);

  return (
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {
          (!started && !!showStartButton) &&  (
            <div style={{ position: 'fixed', top: '0', left: '0', height: '100svh', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button color={'blue'} text="START CAMERA" icon={'camera'} onClick={startCamera} />
            </div> )
        }
        <video autoplay="" muted="" playsinline="" ref={videoRef} style={{display: 'none'}}></video>
        <canvas ref={canvasRef} style={{display: 'none'}}></canvas>
        <canvas ref={resultRef} style={{width: '100%', height: '100%', objectFit: 'contain', visibility: !started && !!showStartButton ? 'hidden' : 'visible' }}></canvas>
      </div>
  );
});

export default Scanner;
