import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Colors from "./basic/common/Colors";
import Fonts from "./basic/common/Fonts";
import Input from "./basic/Input";
import _ from "lodash";
import Icon from "./basic/common/Icon";
import Button from "./basic/Button";
import { useNavigate, useParams } from 'react-router-dom';

const WorksheetRoot = styled.section<{ failed: boolean }>`
  margin-top: 20px;
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  box-sizing: border-box;
  text-align: left;
  font-size: 18px;
  color: ${Colors.grayDark};
  font-family: ${Fonts.outfitRegular.fontFamily};
  border-radius: 10px;
  background-color: ${({ failed }) => (failed ? Colors.redPale : Colors.paleBright)};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const ExpandButtonContainer = styled.div`
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  
  @media (min-width: 480px) {
    &:hover {
      opacity: 1;
    }
`;

// worksheet header container for student name and score
const NameContainer = styled.div` 
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const NameFrame = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 0px 20px 10px;
  gap: 6px;
`;
const Score = styled.div`
  position: relative;
  line-height: 28px;
  display: inline-block;
  margin-top: 4px;
  margin-right: 16px;
`;
const UnscoredName = styled.div`
  width: 100%;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 0px 20px 10px;
  gap: 6px;
  font-family: ${Fonts.outfitRegular.fontFamily};
  font-weight: ${Fonts.outfitRegular.fontWeight};
  color: ${Colors.grayMed};
`;
const AnswerKeyName = styled.div`
  width: 100%;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 0px 20px 10px;
  gap: 6px;
  font-family: ${Fonts.outfitBold.fontFamily};
  font-weight: ${Fonts.outfitBold.fontWeight};
  color: ${Colors.blueDark};
  
`;
const Multipage = styled.div<{ large?: boolean, pageWidth: number, pageHeight: number, calculatedWidth: number, numberOfImages: number }>`
  position: relative;
  display: grid;
  ${({ large, numberOfImages, pageWidth }) => large ? 'grid-template-columns: 1fr;' : numberOfImages > 1 ? `grid-template-columns: repeat(auto-fit, ${pageWidth}px);` : ''}
  grid-template-rows: auto-fit;
  grid-auto-rows: auto-fit;
  place-content: center;
  gap: 10px;
  width: ${({ large, calculatedWidth }) => large ? '100%' : `${calculatedWidth}px`};

  @media (min-width: 480px) {
    &:hover + ${ExpandButtonContainer} {
      opacity: 1;
    }
  }
`;
const PageContainer = styled.div<{ large?: boolean }>`
  max-height: ${({ large }) => (large ? '100%' : '380px')};
  width: ${({ large }) => (large ? '100%' : '294px')};
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  box-shadow: ${({ large }) => (large ? '-2px 4px 6px rgba(0, 0, 0, 0.06)' : 'none')};
  margin: ${({ large }) => (large ? 'auto' : '0')};
`;
const PageImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  border: 1px solid ${Colors.grayLight};
  margin: 0;
`;
const ScoreLarge = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 42px;
  line-height: 42px;
  font-family: ${Fonts.outfitRegular.fontFamily};
  font-weight: ${Fonts.outfitRegular.fontWeight};
  color: ${Colors.grayMed};
  background-color: ${Colors.white};
  padding: 10px;
  border-radius: 40px;
`;

const ScoreIconStyle = styled.img<{absoluteTop: number, absoluteLeft: number, value: boolean}>`
  width: 65px;
  height: 65px;
  position: absolute;
  margin: 0 !important;
  right: 10px;
  top: ${({absoluteTop}) => absoluteTop}px; // Use absoluteBottom prop for positioning
  left: ${({absoluteLeft}) => absoluteLeft}px;
  z-index: 2;
  cursor: pointer;
  
  &:hover, &:focus {
    border-radius: 50%;
    background-color: ${({ value }) => (value === null) ? 'rgba(149, 149, 149, 0.2)' : (value ? 'rgba(0, 167, 27, 0.2)' : 'rgba(211, 55, 42, 0.2)')}; // transparent green, transparent red
  }
`;

// ScoreIcon Component
const ScoreIcon: FunctionComponent<{
  initialValue: float,
  onChange: (value: boolean) => void,
  absoluteTop: number
  absoluteLeft: number
}> = ({ initialValue, onChange, absoluteTop, absoluteLeft }) => {
  const [value, setValue] = useState(initialValue);

  const toggleValue = () => {
    const newValue = !value;
    setValue(newValue);
    onChange(newValue); // Notify parent component of the change
  };

  const iconSrc = (value === null) ? "/score-uncertain.svg" : (value ? "/score.svg" : "/score-2.svg"); // Determine which icon to show

  return (
      <ScoreIconStyle
          onClick={toggleValue}
          alt=""
          src={iconSrc}
          absoluteTop={absoluteTop} // Pass absoluteBottom to styled component
          absoluteLeft={absoluteLeft}
          value={value}
      />
  );
};

// Worksheet Component
const Worksheet: FunctionComponent<{
  studentAssignment?: any;
  rawImage?: any;
  onRenameStudent?: (studentAssignmentId: string, newName: string) => void;
  onCorrectScore?: (studentAssignmentId: string, scoreId: string, newValue: boolean) => void;
  answerKey?: boolean;
  failed?: boolean;
  large?: boolean;
}> = ({ studentAssignment, rawImage, onRenameStudent, onCorrectScore, answerKey, failed, large = false, }) => {
  
  const [studentName, setStudentName] = useState(_.get(studentAssignment, 'studentName'));
  const [scaledPositions, setScaledPositions] = useState<Array<{ top: number; left: number }>>([]);
  const containerRefs = useRef<Array<HTMLDivElement | null>>([]);

  const navigate = useNavigate();
  const { assignmentId } = useParams<{ assignmentId: string }>();

  const [imagesLoaded, setImagesLoaded] = useState<boolean[]>([]);

  const handleScoreChange = (scoreId, newValue: boolean) => {
    // Handle the change as needed, for example, update the state or perform an action
    if (onCorrectScore && studentAssignment?.id) {
      // console.log('scoreId:', scoreId);
      // console.log('value:', newValue);
      // console.log('assignmentId:', studentAssignment.id);
      onCorrectScore(studentAssignment.id, scoreId, newValue);
    }
  };

  const handleStudentNameChange = () => {
    // Handle the change as needed, for example, update the state or perform an action
    if (onRenameStudent && studentAssignment?.id) {
      onRenameStudent(studentAssignment.id, studentName);
    }
  };

  const handleImageLoad = (pageIndex: number, event: React.SyntheticEvent<HTMLImageElement>) => {
    const calculateScaledPositions = () => {
      const newScaledPositions = studentAssignment?.scores.map((score: any, index: number) => {
        const containerElement = containerRefs.current[pageIndex];
        const imageWidth = event.currentTarget.naturalWidth;
        const imageHeight = event.currentTarget.naturalHeight;
        // console.log('image: ', imageWidth, imageHeight);
        if (containerElement) {
          const { width: containerWidth, height: containerHeight } = containerElement.getBoundingClientRect();
          const scaledTop = (score.position / imageHeight) * containerHeight;
          const scaledLeft = (score.positionX / imageWidth) * containerWidth;
          // console.log('container: ', containerWidth, containerHeight);
          // console.log('score pos, image height, container height, scaled top', score.position, imageHeight, containerHeight, scaledTop);
          // console.log('score posX, image width, container width, scaled left', score.positionX, imageWidth, containerWidth, scaledLeft);
          return { top: scaledTop, left: scaledLeft };
        }
        return { top: score.position, left: score.positionX };
      });
      setScaledPositions((prevPositions) => {
        const updatedPositions = [...prevPositions];
        newScaledPositions?.forEach((position, index) => {
          updatedPositions[pageIndex * studentAssignment.scores.length + index] = position;
        });
        return updatedPositions;
    });};
    calculateScaledPositions();
    setImagesLoaded((prevImagesLoaded) => {
      const updatedImagesLoaded = [...prevImagesLoaded];
      updatedImagesLoaded[pageIndex] = true;
      return updatedImagesLoaded;
    });
  };

  const onExpandClick = () => {
    if (assignmentId) {
      // console.log('student assignment: ' + studentAssignment);
      // console.log('raw image: ' + rawImage);
      
      let queryParams = '';
      if (rawImage) {
        queryParams = `?imageId=${encodeURIComponent(rawImage.id)}`;
      } else if (studentAssignment) {
        queryParams = `?studentAssignmentId=${encodeURIComponent(studentAssignment.id)}`;
      }
      navigate(`/by-student/${assignmentId}${queryParams}`);
    }
  };

  const ErrorMessages = {
    IMAGE_RESOLUTION: "Low image resolution",
    NO_ASSIGNMENT: "No assignment detected",
    ANSWER_KEY_ERROR: "Answer key error",
    ERROR_OTHER: "Error",
  };

  const getErrorMessage = (errorCode) => {
    // console.log(ErrorMessages[errorCode] || "Error");
    return ErrorMessages[errorCode] || "Error";
  };

  const containerElement = containerRefs.current[0];
  const containerWidth = containerElement ? containerElement.getBoundingClientRect().width : 294;
  const containerHeight = containerElement ? containerElement.getBoundingClientRect().height : 380;
  const [calculatedWidth, setCalculatedWidth] = useState(294);

  useEffect(() => {
    const calculateWidth = () => {
      const numberOfImages = studentAssignment ? studentAssignment.images.length : 1;
      let maxImagesPerRow = Math.floor(window.innerWidth / (containerWidth + 10)); // max number of images that fit within window width
      if (large) {maxImagesPerRow = 1;}
      const newCalculatedWidth = Math.min((containerWidth + 10) * numberOfImages, (containerWidth + 10) * maxImagesPerRow); // set worksheet width based on how many will fit
      const maxWidth = window.innerWidth - 20; // maximum width is screen width - padding
      let finalWidth = Math.min(newCalculatedWidth, maxWidth); // cap it at maxWidth
      setCalculatedWidth(finalWidth);
    };
    calculateWidth();
    window.addEventListener('resize', calculateWidth);
    return () => { window.removeEventListener('resize', calculateWidth);};
  }, [containerElement, studentAssignment, calculatedWidth, large]);

  const renderWorksheetOuter= () => {
    return (
      <WorksheetRoot failed={failed}>
        <NameContainer>
          {(() => {
            if (studentAssignment) {
              return <NameFrame width={containerWidth}>
                <div style={{ marginTop: '20px' }}><Input ghost={true} icon={'person'} value={studentName} onChange={setStudentName} onBlur={handleStudentNameChange} width={'98%'} /></div>
                {studentAssignment.scores.length > 0 && <Score>{studentAssignment.scores.filter(x => x.value > 0).length}/{studentAssignment.scores.length}</Score>}
              </NameFrame>;
            }
            if (answerKey) {
              return <AnswerKeyName>
              <Icon icon={'key'} size={24} color={Colors.blueDark}></Icon>
                ANSWER KEY
              </AnswerKeyName>
            }
            return (
              <UnscoredName>
                {failed && <Icon icon={'exclamation'} size={24} color={Colors.red}></Icon>} 
                {rawImage.error || failed ? getErrorMessage(rawImage.error) : "UNSCORED"}
              </UnscoredName>
            );
          })()}
        </NameContainer>
        {renderWorksheetInner()}
        <ExpandButtonContainer>
            <Button icon={'expand'} onClick={onExpandClick} text={'Expand'} color={'white'} />
        </ExpandButtonContainer>
      </WorksheetRoot>
    )
  };

  const renderWorksheetInner= () => {
    return (
      <Multipage large={large} pageWidth={containerWidth} pageHeight={containerHeight} calculatedWidth={calculatedWidth} numberOfImages={studentAssignment ? studentAssignment.images.length : 1} >
        {studentAssignment ? (studentAssignment.images.map((image: any, pageIndex: number) => (
            <PageContainer key={pageIndex} ref={(el) => (containerRefs.current[pageIndex] = el)} large={large}>
              <PageImage alt="" src={image.url} onLoad={(event) => handleImageLoad(pageIndex, event)}/>
              {imagesLoaded[pageIndex] && studentAssignment.scores.filter((score: any) => score.page === pageIndex).map((score: any, index: number) => {
                  const { top, left } = scaledPositions[pageIndex * studentAssignment.scores.length + index] || { top: score.position, left: score.positionX };
                  return (
                    <ScoreIcon key={index} initialValue={score.value}
                      onChange={(value) => handleScoreChange(score.id, value)}
                      absoluteTop={top - 32.5} absoluteLeft={left - 32.5} /> );
                })}
            </PageContainer>
          ))
        ) : (
          <PageContainer large={large}>
            <PageImage alt="" src={_.get(rawImage, 'url')} />
          </PageContainer>
        )}
        {/* {large && studentAssignment?.scores && (<ScoreLarge>
          {studentAssignment.scores.filter(x => x.value > 0).length}/{studentAssignment.scores.length}
        </ScoreLarge>)} */}
      </Multipage>
    )
  };

  return (
    <div style={{  }}>
      {!large ? renderWorksheetOuter() : renderWorksheetInner()}
    </div>
  );
};

export default Worksheet;
