// Import statements for React, styled-components, and any other dependencies
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Button from './basic/Button';
import PositionedLayout from './PositionedLayout';
import Fonts from "./basic/common/Fonts";
import Colors from "./basic/common/Colors"; // Ensure this path matches where you place the PositionedLayout component


// Styled components for various parts of the UI
const FilledBar = styled.footer`
    width: 100%;
    background-color: ${Colors.blueLight};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    z-index: 10; // really make sure it's in front
    text-align: center;
    font-size: 10px;
    color: ${Colors.blue};
    font-family: ${Fonts.outfitRegular.fontFamily};
    height: 72px;
    cursor: default;
`;

const WorksheetThumbnailIcon = styled.img`
  height: 52px;
  width: 40px;
  position: absolute;
  margin: 0 !important;
  top: calc(50% - 26px);
  left: 5px;
  z-index: 2;
  object-fit: cover;
  object-position: center;
  cursor: ${props => props.advanceDisabled ? 'default' : 'pointer' };
  opacity: ${props => props.advanceDisabled ? 1 : 1};
`;

const Badge = styled.div`
  width: 24px;
  border-radius: 100px;
  background-color: ${Colors.paleBright};
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 3px;
  box-sizing: border-box;
  z-index: 3;
  position: relative;
  top: -24px;
  left: 26px;
  position: relative;
  letter-spacing: 0.04em;
  line-height: 14px;
  font-weight: 500;
`;

const UiPanel1 = styled.div`
    position: absolute;
    top: 0px;
    border-radius: 50%;
    background-color: ${Colors.blueLight};
    width: 100px;
    height: 100px;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
`;

const RoundedPanel = styled.div`
    height: 100px;
    width: 100px;
    position: relative;
    bottom: 10px;
`;

const ScanButtonWrapper = styled.div`
    height: 100px;
    width: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
    /* Apply the transformation */
`;

// FrameBottomBar component definition
const FrameBottomBar = ({ mode, onMainScanClick, thumbnail, badgeCount = 0, onChangeMode, assignmentId, advanceDisabled, onThumbnailClick, mainDisabled }) => {
    const navigate = useNavigate();

    const onCaptureScanClick = useCallback(() => {
        onMainScanClick();
    }, []);

    const onUploadClick = useCallback(() => {
        onChangeMode('upload');
    }, [navigate]);

    const onScanClick = useCallback(() => {
        onChangeMode('scan');
    }, [navigate]);

    const leftComponent = ( 
        <div style={{ width: '105px' }}><>
            {thumbnail && <WorksheetThumbnailIcon src={thumbnail} alt="" onClick={onThumbnailClick} advanceDisabled={advanceDisabled} />}
            {badgeCount > 0 && <Badge>{badgeCount}</Badge>}
        </></div>
    );

    const centerComponent = (
        <RoundedPanel>
            <UiPanel1 />
            <ScanButtonWrapper>
                <Button color={'white'} size={'large'} onClick={onCaptureScanClick} icon={mode === "upload" ? 'upload' : 'camera'} disabled={mainDisabled} />
            </ScanButtonWrapper>
        </RoundedPanel>
    );

    const rightComponent = (
        <>
            {mode === "upload" ? (
                <Button color={'white'} onClick={onScanClick} text={'Camera'} icon={'camera'} />
            ) : (
                <Button color={'white'} onClick={onUploadClick} text={'Upload'} icon={'upload'} />
            )}
        </>
    );

    return (
        <FilledBar>
            <PositionedLayout
                leftComponent={leftComponent}
                centerComponent={centerComponent}
                rightComponent={rightComponent}
            />
        </FilledBar>
    );
};

export default FrameBottomBar;

