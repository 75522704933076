import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PositionedLayout from './PositionedLayout'; // Ensure this path matches where you place the PositionedLayout component
import Button from './basic/Button';
import Dropdown from './basic/Dropdown';

const FrameTopBar = React.memo(({ onAdvance, advanceDisabled, onValueChange, assignmentId, inProgress, keyDisabled }) => {
    const navigate = useNavigate();

    const onCloseClick = useCallback(() => {
        navigate("/assignments");
    }, [navigate]);

    const onAnswerKeyClick = useCallback(() => {
        if (assignmentId) {
            navigate(`/answer-key/${assignmentId}`); 
    } }, [navigate, assignmentId]); 

    const leftComponent = (
        <>
            <Button color={'blue'} onClick={onCloseClick} icon="home" />
            <Button color={'blue'} onClick={onAnswerKeyClick} icon="key" disabled={keyDisabled} />
        </>
    );

    const centerComponent = (
        <>
            <Dropdown small={true} options={['Right / Wrong']} initialValue={'Right / Wrong'} onChange={onValueChange} width={'146px'} />
        </> 
    );

    const rightComponent = (
        <>
            <Button color={'blue'} text={inProgress ? "Scoring" : "Score All"} onClick={onAdvance} disabled={advanceDisabled || inProgress} icon={inProgress ? 'loading' : 'arrow-right'} />
        </> 
    );

    return (
        <PositionedLayout
            topPadding
            leftComponent={leftComponent}
            centerComponent={centerComponent}
            rightComponent={rightComponent}
            enableTopPadding
        />
    );
});

export default FrameTopBar;
