// StudentAssignmentOperations.js
import { gql, useMutation } from '@apollo/client';
import {GET_ASSIGNMENT_QUERY} from "./useAssignments";
import client from "./apolloClient";

// Mutations
const RENAME_STUDENT_MUTATION = gql`
  mutation renameStudent($studentAssignmentId: String!, $studentName: String!) {
    renameStudent(studentAssignmentId: $studentAssignmentId, studentName: $studentName) {
      id
      studentName
    }
  }
`;

export function useRenameStudent() {
    const [renameStudentMutation] = useMutation(RENAME_STUDENT_MUTATION, {
        optimisticResponse: ({ studentAssignmentId, studentName }) => ({
            renameStudent: {
                __typename: 'StudentAssignment',
                id: studentAssignmentId,
                studentName: studentName,
            },
        }),
    });
    const renameStudent = (studentAssignmentId, studentName) =>
        renameStudentMutation({
            variables: { studentAssignmentId, studentName },
        });

    return { renameStudent };
}


const OVERRIDE_SCORE_MUTATION = gql`
  mutation overrideScore($scoreId: String!, $value: Float!, $type: String!) {
    overrideScore(scoreId: $scoreId, value: $value, type: $type) {
      id
      value
      type
      scoreId
    }
  }
`;

export function useOverrideScore(assignmentId) {
    const [overrideScoreMutation] = useMutation(OVERRIDE_SCORE_MUTATION, {
        optimisticResponse: ({ scoreId, value, type }) => ({
            overrideScore: {
                __typename: 'Score', // Ensure this matches your actual mutation response
                id: scoreId, // This might need to be the ID of the overridden score, not the override action itself
                scoreId: scoreId,
                value: value,
                type: type,
            },
        }),
        update: (cache, { data: { overrideScore } }) => {
            client.writeFragment({
                id: `Score:${overrideScore.scoreId}`,
                fragment: gql`
                    fragment MyScore on Score {
                      value
                    }
                  `,
                data: {
                    value: overrideScore.value,
                },
            });

        },
        // Directly specify the query and variables for refetchQueries
        /*refetchQueries: [
            {
                query: GET_ASSIGNMENT_QUERY,
                variables: { assignmentId }, // Directly use assignmentId that's passed to the function
            },
        ],*/
    });

    const overrideScore = (scoreId, value, type) =>
        overrideScoreMutation({
            variables: { scoreId, value, type },
            // No need to use context to pass assignmentId for refetchQueries
        });

    return { overrideScore };
}




