import {FunctionComponent, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import styled from "styled-components";
import Input from "../components/basic/Input";
import ButtonFixed from "../components/basic/ButtonFixed";
import ButtonLink from "../components/basic/ButtonLink";
import { useGetUserInfo, useDoPasswordReset } from '../apollo/useAuth';
import Fonts from "../components/basic/common/Fonts";
import Colors from "../components/basic/common/Colors";

const ResetRoot = styled.div`
  width: 100%;
  height: 100svh;
  position: relative;
  background-color: ${Colors.pale};
  box-shadow: 0px 3px 6px rgba(18, 15, 40, 0.12);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  color: ${Colors.grayDark};
  font-family: ${Fonts.outfitRegular.fontFamily};
`;

const CenterContainer = styled.div`
  margin: 0;
  width: 343px;
  height: calc(100svh - 42px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  box-sizing: border-box;
  gap: 0px;
  z-index: 1;
  background-color: ${Colors.pale};
`;
const ResetHeader = styled.h2`
  margin: 0;
  position: relative;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  font-family: ${Fonts.outfitRegular.fontFamily};
  color: ${Colors.grayDark};
  text-align: left;
`;
const SubText = styled.div`
  position: relative;
  line-height: 20px;
  font-weight: 300;
  padding-bottom: 26px;
  cursor: text;
`;
const InputContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
    padding-top: 20px;
`;
const ButtonContainer = styled.div`
  padding: 14px 0px 10px 0px;
`;

const BottomContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    bottom: 0px;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 10px 10px 10px 10px;
    max-width: 400px;
`;
const BottomFixed = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const FooterText = styled.div`
  position: relative;
  line-height: 20px;
  font-weight: 300;
  cursor: text;
`;

const ResetPassword: FunctionComponent = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const resetToken = searchParams.get('resetToken');
    // const { data: userData } = useGetUserInfo(); // Remove - wont work b/c no authed token
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const { doPasswordReset } = useDoPasswordReset();

    const onResetPasswordClick = async () => {
        if (!resetToken || password === '') {
            setError('password');
            return;
        }

        try {
            await doPasswordReset(resetToken, password);
            navigate("/log-in"); // Adjust this as necessary based on your routing
        } catch (error: any) {
            console.error('Reset password error:', error);
            setError(error.message || 'Failed to reset password.');
        }
    };

    const onLoginClick = () => {
        navigate("/login"); // Adjust this as necessary based on your routing
    };

    return (
        <ResetRoot>
            <CenterContainer>
                <ResetHeader>New Password</ResetHeader>
                {/*<SubText>For {userData?.getUserInfo?.email ?? 'your email'}</SubText>*/}
                <InputContainer>
                    <Input
                        placeholder="Password"
                        icon={'lock'}
                        type="password"
                        value={password}
                        onChange={(value) => setPassword(value)}
                        errorMessage={error === 'password' ? "Invalid password." : ''}
                    />
                </InputContainer>
                <ButtonContainer>
                    <ButtonFixed text="Reset Password" onClick={onResetPasswordClick} />
                </ButtonContainer>
            </CenterContainer>
            <BottomContainer>
                <BottomFixed>
                    <FooterText>Back to</FooterText>
                    <ButtonLink text="Log In" onClick={onLoginClick} />
                </BottomFixed>
            </BottomContainer>
        </ResetRoot>
    );
};

export default ResetPassword;