import React, { FunctionComponent, useEffect } from "react";
import styled from "styled-components";
import Fonts from "../components/basic/common/Fonts";
import Colors from "../components/basic/common/Colors";
import Icon from "../components/basic/common/Icon";
import { useGetUserInfo } from '../apollo/useAuth';

const WaitlistRoot = styled.div`
    height: 100svh;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: ${Colors.pale};
`;
const TopContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0px 0px 0px;
`;

const CenterContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 10px 10px 10px 10px;
    max-width: 420px;
    // height: calc(100svh - 46px - 40px);
`;
const WaitlistHeader = styled.h1`
    position: relative;
    font-color: ${Colors.grayDark};
    font-weight: ${Fonts.outfitBold.fontWeight};
    font-family: ${Fonts.outfitBold.fontFamily};
    font-size: 48px;
    line-height: 48px;
    margin: 0px 0px 30px 0px;
`;
const WaitlistMessage = styled.div`
    position: relative;
    font-color: ${Colors.grayDark};
    font-weight: ${Fonts.outfitRegular.fontWeight};
    font-family: ${Fonts.outfitRegular.fontFamily};
    font-size: 16px;
    line-height: 20px;
    margin: 0px 0px 20px 0px;
`;

const BottomFixed = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    bottom: 0px;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 10px 10px 10px 10px;
    max-width: 500px;
`;
const BottomContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
const WaitlistFooter = styled.div`
    position: relative;
    font-color: ${Colors.grayDark};
    font-weight: ${Fonts.outfitLight.fontWeight};
    font-family: ${Fonts.outfitLight.fontFamily};
    font-size: 14px;
    line-height: 20px;
`;

const Waitlist: FunctionComponent = () => {

    const { data: userData } = useGetUserInfo();

    return (
        <WaitlistRoot>
            <TopContainer>
                <Icon icon={'logo'} size={36} color={Colors.blue}></Icon>
            </TopContainer>
            <CenterContainer>
                <WaitlistHeader>Welcome to <span style={{color: Colors.blue}}>SNAPALYZE</span>!</WaitlistHeader>
                <WaitlistMessage>Thank you for joining the waitlist!</WaitlistMessage>
                <WaitlistMessage>We’re onboarding more teachers every day. We’ll reach out to {userData?.getUserInfo?.email ?? 'your email'} to grant you access soon.</WaitlistMessage>
            </CenterContainer>
            <BottomFixed>
                <BottomContainer>
                    <WaitlistFooter>Contact support@snapalyze.com in the meantime with any questions.</WaitlistFooter>
                </BottomContainer>
            </BottomFixed>
        </WaitlistRoot>
    );
};

export default Waitlist;