const Colors = {
    blue: '#1456F0',
    blueDark: '#2456CF',
    blueLight: '#3370FF',
    blueTransparent: 'rgba(20, 86, 240, 0.2)',
    white: '#FFFFFF',
    grayLight: '#CECECE',
    grayMed: '#424956',
    grayDark: '#1E1E1E',
    pale: '#F8FAFF',
    paleBright: '#F0F4FF',
    red: '#D3372A',
    green: '#008E17',
    yellow: '#D7A700',
    redDark: '#BD1609',
    redLight: '#E84134',
    redPale: '#FBEFEB',
    greenPale: '#E5F5E7',
    yellowPale: '#FFFDDB',
    black: '#000000',
    transparent: 'rgba(0, 0, 0, 0)',
}

export const blendWithWhite = (hex, opacity) => `#${hex.replace(/^#/, '').match(/.{2}/g).map(c => Math.round((parseInt(c, 16) * opacity) + (255 * (1 - opacity))).toString(16).padStart(2, '0')).join('')}`;

export default Colors;