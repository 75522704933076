import React, {useImperativeHandle, forwardRef, useRef, useEffect} from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { useImageUpload } from '../apollo/useImageUpload';
import Fonts from "./basic/common/Fonts";
import Colors from "./basic/common/Colors"; // Assuming this path is correct

const FRAMEDROPFILESHChild = styled.div`
  width: 340px;
  height: 150px;
  position: relative;
  border-radius: 30px;
  border: 4px dashed ${Colors.blueTransparent};
  box-sizing: border-box;
  display: none;
`;
const UploadIcon = styled.img`
  height: 84px;
  width: 84px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 3;
`;
const UploadIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 0px 0px;
`;
const DropFilesHere = styled.div`
  width: auto;
  height: 16px;
  position: relative;
  line-height: 26px;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
  z-index: 2;
`;
const FRAMEDROPFILESH = styled.div`
  flex: 1;
  border-radius:30px;
  border: 4px dashed ${Colors.blueTransparent};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 22px 0px 28px 0px;
  z-index: 1;
`;
const UploadZoneFrame = styled.div`
  flex: 1;
  border-radius: 40px;
  background-color: ${props => props.isDragging ? Colors.paleBright : Colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 9px 5px 9px;
  cursor: copy;
`;
const UploadZoneFrameWrapperRoot = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 24px 0px;
  text-align: center;
  font-size: 16px;
  color: ${Colors.blueDark};
  font-family: ${Fonts.outfitRegular.fontFamily};
`;

const UploadZone = forwardRef(({ assignmentId, imageUploaded, onNewThumbnail, onNewFile, uploadLoadingUpdated, 
  onUploadStart, onUploadSuccess, onUploadFailure }, ref) => {
  // Utilize the custom hook for image upload
  const { uploadImage, loading } = useImageUpload();
  const inputRef = useRef(null); // Step 2: Creating a ref

  useEffect(() => {
    // Check if uploadLoadingUpdated is not null before calling
    if (uploadLoadingUpdated != null) {
      uploadLoadingUpdated(loading);
    }
  }, [loading, uploadLoadingUpdated])

  useImperativeHandle(ref, () => ({
    openFilePicker,
  }));

  const openFilePicker = () => {
    doOpenFilePicker()
  }

  const { getRootProps, getInputProps, open: doOpenFilePicker, isDragActive } = useDropzone({
    onDrop: acceptedFiles => {
      acceptedFiles.forEach(file => {
        onUploadStart && onUploadStart(file.name);
        if (['image/jpeg', 'image/png', 'image/webp', 'image/bmp', 'application/pdf'].includes(file.type)) {
          onNewFile(file.name);
          const reader = new FileReader();
          reader.onloadend = () => {
            uploadImage(assignmentId, file).then(uploadResult => {
              if (uploadResult && uploadResult.success) {
                onUploadSuccess && onUploadSuccess(file.name);
                const updatedAssignmentId = uploadResult.assignmentId ? uploadResult.assignmentId : assignmentId;
                imageUploaded?.(uploadResult.imageId, updatedAssignmentId, file.name);
                onNewThumbnail?.(reader.result.toString()); //set thumbnail after upload success
              } else {
                onUploadFailure && onUploadFailure(file.name);
                console.error('Upload failed', uploadResult.message);
              }
            });
          };
          reader.readAsDataURL(file);
        } else {
          console.error('File rejected: Only PNG, JPG, JPEG, WEBP, BMP, and PDF files are accepted.');
        }
      });
    },
    noClick: false, // Prevent the default click action to open the file dialog
  });

  // Expose the `openFilePicker` method to parent components through `ref`
  useImperativeHandle(ref, () => ({
    openFilePicker,
  }));

  return (
      <UploadZoneFrameWrapperRoot {...getRootProps()}>
        <UploadZoneFrame isDragging={isDragActive}>
          <input {...getInputProps()} style={{ display: 'none' }} />
          <FRAMEDROPFILESH>
            <FRAMEDROPFILESHChild />
            <UploadIconWrapper>
              <UploadIcon loading="eager" alt="" src="/upload-icon.svg" />
            </UploadIconWrapper>
            <DropFilesHere>DROP HERE TO UPLOAD</DropFilesHere>
          </FRAMEDROPFILESH>
        </UploadZoneFrame>
      </UploadZoneFrameWrapperRoot>
  );
});

export default UploadZone;

