// useAuth.js
import { gql, useQuery, useMutation, useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

// Login Mutation
const REGISTER_MUTATION = gql`
  mutation register($name: String, $email: String!, $pass: String!, $subjects: [String!]!, $grades: [String!]!) {
    register(name: $name, email: $email, pass: $pass, subjects: $subjects, grades: $grades) {
      user {
        id
        email
        role
        subjects
        grades
      }
      token
      waitlist
    }
  }
`;

const LOGIN_MUTATION = gql`
  mutation login($email: String!, $pass: String!) {
    login(email: $email, pass: $pass) {
      user {
        id
        email
        role
        subjects
        grades
      }
      token
      waitlist
    }
  }
`;

const GET_USER_INFO_QUERY = gql`
  query getUserInfo {
    getUserInfo {
      id
      email
      role
      subjects
      grades
      hasAssignment
    }
  }
`;

export function useGetUserInfo() {
    const { data, loading, error } = useQuery(GET_USER_INFO_QUERY);
    return { data, loading, error };
}

// Hook for login functionality

export function useLogin() {
    const navigate = useNavigate(); // Initialize useNavigate hook
    const [loginMutation, { data: loginData, loading: loginLoading, error: loginError }] = useMutation(LOGIN_MUTATION, {
        onCompleted: (data) => {
            console.log("login 3");
            if (data.login.waitlist) { // Check if waitlist is true
                window.location.href = 'https://snapalyze.com/waitlist'; // redirect to website waitlist URL
            } else if (data.login.token) { // Check if token is present and waitlist is not true
                console.log("login 4");
                localStorage.setItem('token', data.login.token);
                console.log("login 5");
            }
        },
    });

    const login = async (email, pass) => {
        try {
            await loginMutation({ variables: { email, pass } });
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    return { login, loginData, loginLoading, loginError };
}

export function useRegister() {
    const navigate = useNavigate(); // Initialize useNavigate hook
    const [registerMutation, { data: registerData, loading: registerLoading, error: registerError }] = useMutation(REGISTER_MUTATION, {
        onCompleted: (data) => {
            console.log("Registration complete data", data);
            if (data.register.waitlist) { // Check if waitlist is true
                window.location.href = 'https://snapalyze.com/waitlist'; // redirect to website waitlist URL
            } else if (data.register.token) { // Check if token is present and waitlist is not true
                localStorage.setItem('token', data.register.token);
            }
        },
    });

    const register = async (name, email, pass, subjects, grades) => {
        try {
            await registerMutation({ variables: { name, email, pass, subjects, grades } });
        } catch (error) {
            console.error('Registration error:', error);
        }
    };

    return { register, registerData, registerLoading, registerError };
}

const UPDATE_PROFILE_MUTATION = gql`
  mutation updateProfile($role: String, $subjects: [String!], $grades: [String!]) {
    updateProfile(role: $role, subjects: $subjects, grades: $grades) {
        id
        email
        role
        subjects
        grades
      }
  }
`;

export function useUpdateProfile() {
  const [updateProfileMutation, { data, loading, error }] = useMutation(UPDATE_PROFILE_MUTATION, {
    onCompleted: (data) => {
      console.log('Profile update successful:', data);
    },
  });

  const updateProfile = async (role, subjects, grades) => {
    try {
      await updateProfileMutation({ variables: { role, subjects, grades } });
    } catch (error) {
      console.error('Update Profile error:', error);
    }
  };

  return { updateProfile, data, loading, error };
}

export function useLogout() {
    const client = useApolloClient(); // Get the ApolloClient instance

    const logout = async () => {
        try {
            // Clear the token from localStorage
            localStorage.setItem('token', null);

            // Erase Apollo client cache
            await client.clearStore();
            // Alternatively, if you want to reset the store and refetch active queries:
            // await client.resetStore();
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return { logout };
}

const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

const DO_PASSWORD_RESET_MUTATION = gql`
  mutation doPasswordReset($token: String!, $pass: String!) {
    doPasswordReset(token: $token, pass: $pass) {
      user {
        id
        email
      }
      token
    }
  }
`;

export function useRequestPasswordReset() {
    const [requestPasswordResetMutation, { data, loading, error }] = useMutation(REQUEST_PASSWORD_RESET_MUTATION, {
        onCompleted: (data) => {
            console.log('Password reset request successful:', data);
        },
    });

    const requestPasswordReset = async (email) => {
        try {
            await requestPasswordResetMutation({ variables: { email } });
        } catch (error) {
            console.error('Request Password Reset error:', error);
        }
    };

    return { requestPasswordReset, data, loading, error };
}

export function useDoPasswordReset() {
    const [doPasswordResetMutation, { data, loading, error }] = useMutation(DO_PASSWORD_RESET_MUTATION, {
        onCompleted: (data) => {
            console.log('Password reset successful:', data);
            if (data.doPasswordReset.token) {
                localStorage.setItem('token', data.doPasswordReset.token);
            }
        },
    });

    const doPasswordReset = async (token, pass) => {
        try {
            await doPasswordResetMutation({ variables: { token, pass } });
        } catch (error) {
            console.error('Do Password Reset error:', error);
        }
    };

    return { doPasswordReset, data, loading, error };
}