// AssignmentOperations.js
import { gql, useMutation, useQuery } from '@apollo/client';

// Queries
const GET_ASSIGNMENTS_QUERY = gql`
  query getAssignments {
    getAssignments {
      id
      name
      createdAt
      status
      imageCount
      studentAssignmentCount
      answerKeyImageCount
    }
  }
`;

export function useGetAssignments() {
    const { data, loading, error, refetch } = useQuery(GET_ASSIGNMENTS_QUERY);
    return { data, loading, error, refetch };
}

export const GET_ASSIGNMENT_QUERY = gql`
  query getAssignment($assignmentId: String!) {
    getAssignment(assignmentId: $assignmentId) {
      id
      name
      scoringMethod
      createdAt
      images {
        id
        uploadDate
        url
        studentAssignmentId
        isAnswerKey
      }
      status
      studentAssignments {
        id
        studentName
        images {
          id
          uploadDate
          url
        }
        scores {
          id
          value
          position
          page
        }
      }
    }
  }
`;

export function useGetAssignment(assignmentId) {

    const skipQuery = !assignmentId;

    const { data, loading, error, refetch } = useQuery(GET_ASSIGNMENT_QUERY, {
        variables: { assignmentId },
        skip: skipQuery
    });
    return { data, loading, error, refetch };
}

// Mutations
const SCORE_ASSIGNMENT_MUTATION = gql`
  mutation scoreAssignment($assignmentId: String!, $scoringType: ScoringMethod!) {
    scoreAssignment(assignmentId: $assignmentId, scoringType: $scoringType)  {
      id
      name
      status
    }
  }
`;

export function useScoreAssignment() {
    const [scoreAssignmentMutation] = useMutation(SCORE_ASSIGNMENT_MUTATION);
    const scoreAssignment = (assignmentId, scoringType) =>
        scoreAssignmentMutation({ variables: { assignmentId, scoringType } });

    return { scoreAssignment };
}

const RENAME_ASSIGNMENT_MUTATION = gql`
  mutation renameAssignment($assignmentId: String!, $name: String!) {
    renameAssignment(assignmentId: $assignmentId, name: $name) {
      id
      name
    }
  }
`;

export function useRenameAssignment() {
    const [renameAssignmentMutation] = useMutation(RENAME_ASSIGNMENT_MUTATION, {
        optimisticResponse: ({ assignmentId, name }) => ({
            renameAssignment: {
                __typename: 'Assignment',
                id: assignmentId,
                name: name,
            },
        }),
    });
    const renameAssignment = (assignmentId, name) =>
        renameAssignmentMutation({
            variables: { assignmentId, name },
        });

    return { renameAssignment };
}

const DELETE_ASSIGNMENT_MUTATION = gql`
  mutation deleteAssignment($assignmentId: String!) {
    deleteAssignment(assignmentId: $assignmentId)
  }
`;

export function useDeleteAssignment() {
    const [deleteAssignmentMutation] = useMutation(DELETE_ASSIGNMENT_MUTATION, {
        // update: (cache, { variables: { assignmentId } }) => {
        //    cache.evict({ id: `Assignment:${assignmentId}` });
        //    cache.gc();
        // },
        refetchQueries: [
            {
                query: GET_ASSIGNMENTS_QUERY,
                variables: {},
            },
        ]
    });
    const deleteAssignment = (assignmentId) =>
        deleteAssignmentMutation({ variables: { assignmentId } });

    return { deleteAssignment };
}

const EXPORT_ASSIGNMENT_MUTATION = gql`
  mutation exportAssignment($assignmentId: String!) {
    exportAssignment(assignmentId: $assignmentId)
  }
`;

export function useExportAssignmentCSV() {
    const [exportAssignment, { loading, error }] = useMutation(EXPORT_ASSIGNMENT_MUTATION);

    const downloadCSV = async (assignmentId, assignmentName) => {
        try {
            const { data } = await exportAssignment({ variables: { assignmentId } });
            const csvData = data.exportAssignment; // Assuming this returns the CSV data as a string
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const downloadUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${assignmentName}.csv`); // Customize the filename as needed
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (err) {
            console.error("Error exporting assignment CSV:", err);
        }
    };

    return { downloadCSV, loading, error };
}

const SET_ANSWER_KEY_IMAGES_MUTATION = gql`
  mutation setAnswerKeyImages($assignmentId: String!, $imageIds: [ID!]!) {
    setAnswerKeyImages(assignmentId: $assignmentId, imageIds: $imageIds)
  }
`;

export function useSetAnswerKeyImages() {
    const [setAnswerKeyImagesMutation] = useMutation(SET_ANSWER_KEY_IMAGES_MUTATION);

    const setAnswerKeyImages = (assignmentId, imageIds) =>
        setAnswerKeyImagesMutation({
            variables: { assignmentId, imageIds },
            refetchQueries: [
                // Optionally refetch queries to update the UI with new data
                {
                    query: GET_ASSIGNMENT_QUERY,
                    variables: { assignmentId },
                },
                // You can include other queries here if needed
            ],
            // Optional: Update cache or handle the mutation result
            update: (cache, { data }) => {
                // Implement any needed cache updates or post-mutation actions here
            },
        });

    return { setAnswerKeyImages };
}
