import { FunctionComponent, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import _ from 'lodash';
import ButtonToggle from "./ButtonToggle";

const ButtonToggleGroupRoot = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start; 
    gap: 5px; 
`;

type ButtonToggleGroupProps = {
    options: string[];
    multiselect?: boolean;
    initialValue?: any; 
    onChange?: (selectedOptions: string[]) => void; 
};

const ButtonToggleGroup: FunctionComponent<ButtonToggleGroupProps> = ({
    options,
    multiselect = true,
    initialValue = [], 
    onChange = () => {}, 
}) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>(Array.isArray(initialValue) ? initialValue : []);
    const prevInitialValueRef = useRef(initialValue);

    useEffect(() => {
      if (!_.isEqual(prevInitialValueRef.current, initialValue)) {
        setSelectedOptions(Array.isArray(initialValue) ? initialValue : []);
        prevInitialValueRef.current = initialValue;
      }
    }, [initialValue]);

    const handleToggle = (option: string) => {
        let newSelectedOptions = [...selectedOptions];
        if (multiselect) {
            if (newSelectedOptions.includes(option)) {
                newSelectedOptions = newSelectedOptions.filter(o => o !== option);
            } else {
                newSelectedOptions.push(option);
            }
        } else {
            newSelectedOptions = [option];
        }
        setSelectedOptions(newSelectedOptions);
        onChange(newSelectedOptions);
    };
    // useEffect(() => { console.log('Selected: ' + selectedOptions); }, [selectedOptions]);

    return (
        <ButtonToggleGroupRoot>
            {options.map((option, index) => (
                <ButtonToggle
                    key={index}
                    text={option}
                    onToggle={() => handleToggle(option)}
                    isSelected={selectedOptions.includes(option)}
                    useExternalState={true}
                />
            ))}
        </ButtonToggleGroupRoot>
    );
};

export default ButtonToggleGroup;