import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Fonts from "../components/basic/common/Fonts";
import Colors from "../components/basic/common/Colors";
import Icon from "../components/basic/common/Icon";

const UnavailableRoot = styled.div`
    height: 100svh;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: ${Colors.pale};
`;

const CenterContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 10px 10px 10px 10px;
    max-width: 420px;
`;
const UnavailableHeader = styled.h1`
    position: relative;
    font-color: ${Colors.grayDark};
    font-weight: ${Fonts.outfitBold.fontWeight};
    font-family: ${Fonts.outfitBold.fontFamily};
    font-size: 48px;
    line-height: 48px;
    margin: 0px 0px 30px 0px;
`;
const UnavailableIllustration = styled.img`
    margin: 0px 0px 30px 0px;
`;
const UnavailableMessage = styled.div`
    position: relative;
    font-color: ${Colors.grayDark};
    font-weight: ${Fonts.outfitRegular.fontWeight};
    font-family: ${Fonts.outfitRegular.fontFamily};
    font-size: 16px;
    line-height: 20px;
    margin: 0px 0px 0px 0px;
`;

const Unavailable: FunctionComponent = () => {

    return (
        <UnavailableRoot>
            <CenterContainer>
                <UnavailableHeader>Oops!</UnavailableHeader>
                <UnavailableIllustration src={`${process.env.PUBLIC_URL}/cat-sleeping-illustration.svg`} />
                <UnavailableMessage>Snapalyze is currently unavailable.</UnavailableMessage>
                <UnavailableMessage>We’ll be back soon!</UnavailableMessage>
            </CenterContainer>
        </UnavailableRoot>
    );
};

export default Unavailable;