import { FunctionComponent, useState } from "react";
import styled from "styled-components";
import Icon from './common/Icon';
import Colors, { blendWithWhite } from './common/Colors';
import Fonts from './common/Fonts';
import _ from 'lodash';

const Text1 = styled.div<{ color: string }>`
    font-size: 16px;
    line-height: 22px;
    font-weight: ${Fonts.outfitRegular.fontWeight};
    font-family: ${Fonts.outfitRegular.fontFamily};
    color: ${({ color }) => color};
    text-align: left;
`;

const ButtonPrimaryRoot = styled.button<{ size?: 'small' | 'medium' | 'large', color?: string, disabled?: boolean, noText?: boolean, backgroundColor?: string, borderColor?: string, iconTextColor?: string, }>`
    border: ${({ borderColor }) => `1px solid ${borderColor}`};
    padding: ${({ noText }) => noText ? '10px' : '7px 11px 7px 13px'};
    color: ${({ iconTextColor }) => iconTextColor};
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: ${({ noText }) => noText ? '50%' : '42px'};
    width: ${({ noText, size }) => {
        if (noText) {
            switch (size) {
                case 'large':
                    return '72px';
                case 'medium':
                    return '52px';
                default:
                    return '40px';
            }
        } else {
            return 'auto';
    }}};
    height: ${({ noText, size }) => {
        if (noText) {
            switch (size) {
                case 'large':
                    return '72px';
                case 'medium':
                    return '52px';
                default:
                    return '40px';
            }
        } else {
            return 'auto';
    }}};
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    text-wrap: nowrap;
    flex-shrink: 0;
    white-space: nowrap;
    gap: 5px;
    cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
`;

export type ButtonType = {
    text?: string | null;
    icon?: string | null;
    onClick: () => void;
    disabled?: boolean;
    color?: string;
    size?: 'small' | 'medium' | 'large';
};

const Button: FunctionComponent<ButtonType> = ({
   text = null,
   icon,
   onClick,
   disabled = false,
   color = null,
   size = 'small'
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const handleClick = _.debounce(() => {
        // Your click handler logic here
        onClick();
    }, 500);

    const onClickRaw = (e) => {
        e.preventDefault()
        handleClick();
    }

    const noText = text === null;

    const setBackgroundColor = (color, state) => {
        let backgroundColor = Colors.blue;
        if (color === 'blue'){
            if (state === 'hover'){ backgroundColor = Colors.blueLight; }
            else if (state === 'active'){ backgroundColor = Colors.blueDark; }
            else if (state === 'disabled'){ backgroundColor = blendWithWhite(Colors.blue, 0.4) }
            else { backgroundColor = Colors.blue; }
        }
        else if (color === 'white'){
            if (state === 'default' || 'hover' || 'active'){ backgroundColor = Colors.white }
            else if (state === 'disabled'){ backgroundColor = Colors.white }
            else { backgroundColor = Colors.white }
        }
        else if (color === 'red'){
            if (state === 'hover'){ backgroundColor = Colors.redLight }
            else if (state === 'active'){ backgroundColor = Colors.redDark }
            else if (state === 'disabled'){ backgroundColor = blendWithWhite(Colors.red, 0.4) }
            else { backgroundColor = Colors.red }
        }
        else if (color === 'ghost'){
            if (state === 'hover'){ backgroundColor = Colors.white }
            else if (state === 'active'){ backgroundColor = Colors.white }
            else if (state === 'disabled'){ backgroundColor = Colors.transparent }
            else { backgroundColor = Colors.transparent }
        }
        else if (color === 'light'){
            backgroundColor = Colors.pale;
            if (state === 'disabled'){ backgroundColor = blendWithWhite(Colors.pale, 0.4) }
        }
        return backgroundColor;
    };
    const setBorderColor = (color, state) => {
        let borderColor = Colors.blue;
        if (color === 'blue'){
            if (state === 'hover'){ borderColor = Colors.blueLight; }
            else if (state === 'active'){ borderColor = Colors.blueDark; }
            else if (state === 'disabled'){ borderColor = blendWithWhite(Colors.blue, 0.4) }
            else { borderColor = Colors.blue; }
        }
        else if (color === 'white'){
            if (state === 'hover'){ borderColor = Colors.blueLight }
            else if (state === 'active'){ borderColor = Colors.blueDark }
            else { borderColor = Colors.white }
        }
        else if (color === 'red'){
            if (state === 'hover'){ borderColor = Colors.redLight }
            else if (state === 'active'){ borderColor = Colors.redDark }
            else if (state === 'disabled'){ borderColor = blendWithWhite(Colors.red, 0.4) }
            else { borderColor = Colors.red }
        }
        else if (color === 'ghost'){
            if (state === 'hover'){ borderColor = Colors.white }
            else if (state === 'active'){ borderColor = Colors.grayLight }
            else{ borderColor = Colors.transparent }
        }
        else if (color === 'light'){
            if (state === 'hover'){ borderColor = Colors.blueLight; }
            else if (state === 'active'){ borderColor = Colors.blueDark; }
            else { borderColor = Colors.pale; }
        }
        else{ borderColor = Colors.white; }
        return borderColor;
    };
    const seticonTextColor = (color, state) => {
        let iconTextColor = Colors.blue;
        if (color === 'blue'){
            iconTextColor = Colors.white;
        }
        else if (color === 'white'){
            if (state === 'hover'){ iconTextColor = Colors.blueLight }
            else if (state === 'active'){ iconTextColor = Colors.blueDark }
            else if (state === 'disabled'){ iconTextColor = blendWithWhite(Colors.blue, 0.4) }
            else { iconTextColor = Colors.blue }
        }
        else if (color === 'red'){
            iconTextColor = Colors.white;
        }
        else if (color === 'ghost'){
            if (state === 'disabled'){ iconTextColor = blendWithWhite(Colors.grayMed, 0.4)}
            else { iconTextColor = Colors.grayMed; }
        }
        else if (color === 'light'){
            if (state === 'hover'){ iconTextColor = Colors.blueLight; }
            else if (state === 'active'){ iconTextColor = Colors.blueDark; }
            else if (state === 'disabled'){ iconTextColor = blendWithWhite(Colors.blue, 0.4) }
            else { iconTextColor = Colors.blue; }
        }
        else {
            iconTextColor = Colors.white;
        }
        return iconTextColor;
    };

    const state = isActive ? 'active' : isHovered ? 'hover' : disabled ? 'disabled' : 'default';
    const backgroundColor = setBackgroundColor(color, state);
    const borderColor = setBorderColor(color, state);
    const iconTextColor = seticonTextColor(color, state);

    return (
        <ButtonPrimaryRoot
            onClick={onClickRaw}
            disabled={disabled}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            iconTextColor={iconTextColor}
            noText={noText}
            size={size}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => { setIsHovered(false); setIsActive(false); }}
            onMouseDown={() => setIsActive(true)}
            onMouseUp={() => setIsActive(false)}
        >
            {text && <Text1 color={iconTextColor} disabled={disabled}>{text}</Text1>}
            {icon && <Icon onClick={handleClick} icon={icon} color={iconTextColor} size={size === 'large' ? 40 : size === 'medium' ? 30 : 22} />}
        </ButtonPrimaryRoot>
    );
};

export default Button;

