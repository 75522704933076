import {FunctionComponent, useState} from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Input from "../components/basic/Input";
import ButtonFixed from "../components/basic/ButtonFixed";
import ButtonLink from "../components/basic/ButtonLink";
import { useRequestPasswordReset } from '../apollo/useAuth';
import Fonts from "../components/basic/common/Fonts";
import Colors from "../components/basic/common/Colors";

const ForgotRoot = styled.div`
  width: 100%;
  height: 100svh;
  position: relative;
  background-color: ${Colors.pale};
  box-shadow: 0px 3px 6px rgba(18, 15, 40, 0.12);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  color: ${Colors.grayDark};
  font-family: ${Fonts.outfitRegular.fontFamily};
`;

const CenterContainer = styled.div`
  margin: 0;
  width: 343px;
  height: calc(100svh - 42px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  box-sizing: border-box;
  gap: 0px;
  z-index: 1;
  background-color: ${Colors.pale};
`;
const ForgotHeader = styled.h2`
  margin: 0;
  position: relative;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  font-family: ${Fonts.outfitRegular.fontFamily};
  color: ${Colors.grayDark};
  text-align: left;
`;
const SubText = styled.div`
  position: relative;
  line-height: 20px;
  font-weight: 300;
  padding-bottom: 26px;
  cursor: text;
`;
const InputContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`;
const ButtonContainer = styled.div`
  padding: 14px 0px 10px 0px;
`;

const BottomContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    bottom: 0px;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 10px 10px 10px 10px;
    max-width: 400px;
`;
const BottomFixed = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const FooterText = styled.div`
  position: relative;
  line-height: 20px;
  font-weight: 300;
  cursor: text;
`;

const ForgotPassword: FunctionComponent = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const { requestPasswordReset } = useRequestPasswordReset();

    const onRequestResetClick = async () => {
        try {
            await requestPasswordReset(email);
            // Optionally, inform the user that the email has been sent
            alert("If the email matches an account, a reset link will be sent.");
            navigate("/");
        } catch (error) {
            console.error('Request Reset error:', error);
            // Optionally, show an error to the user
        }
    };

    const onLoginClick = () => {
        navigate("/login"); // Adjust this based on your routing
    };

    return (
        <ForgotRoot>
            <CenterContainer>
                <ForgotHeader>Forgot Password?</ForgotHeader>
                <SubText>No worries, we’ll email you reset instructions.</SubText>
                <InputContainer>
                    <Input
                        placeholder="Email"
                        propMinWidth="182px"
                        icon={'mail'}
                        value={email}
                        onChange={(value) => setEmail(value)}
                    />
                </InputContainer>
                <ButtonContainer>
                    <ButtonFixed text="Request Reset" onClick={onRequestResetClick} />
                </ButtonContainer>
            </CenterContainer>
            <BottomContainer>
                <BottomFixed>
                    <FooterText>Back to</FooterText>
                    <ButtonLink text="Log In" onClick={onLoginClick} />
                </BottomFixed>
            </BottomContainer>
        </ForgotRoot>
    );
};

export default ForgotPassword;