import {FunctionComponent, useCallback, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Input from "../components/basic/Input";
import ButtonFixed from "../components/basic/ButtonFixed";
import ButtonLink from "../components/basic/ButtonLink";
import Colors from "../components/basic/common/Colors";
import Fonts from "../components/basic/common/Fonts";
import { useLogin, useGetUserInfo } from "../apollo/useAuth";
import { useGetAssignments } from "../apollo/useAssignments";

const SignUpRoot = styled.div`
  width: 100%;
  height: 100svh;
  position: relative;
  background-color: ${Colors.pale};
  box-shadow: 0px 3px 6px rgba(18, 15, 40, 0.12);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  color: ${Colors.grayDark};
  font-family: ${Fonts.outfitRegular.fontFamily};
`;

const SignUpParent = styled.div`
  margin: 0;
  width: 100%;
  max-width: 320px;
  height: calc(100svh - 42px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  box-sizing: border-box;
  gap: 0px;
  z-index: 1;
  background-color: ${Colors.pale};
`;
const SignUp1 = styled.h2`
  margin: 0;
  position: relative;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  font-family: ${Fonts.outfitRegular.fontFamily};
  color: ${Colors.grayDark};
  text-align: left;
  padding-bottom: 26px;
`;
const Input1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`;
const ButtonContainer = styled.div`
  padding: 14px 0px 10px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ForgotContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const ForgotText = styled.div`
  position: relative;
  line-height: 20px;
  font-weight: 300;
  cursor: text;
`;

const BottomFixed = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  bottom: 0px;
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 10px 10px 10px 10px;
  max-width: 400px;
`;
const BottomContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const FooterText = styled.div`
  position: relative;
  line-height: 20px;
  font-weight: 300;
  cursor: text;
`;

const LogIn: FunctionComponent = () => {
  const navigate = useNavigate();
  const { login, loginData, loginLoading, loginError } = useLogin(); // Invoke the useLogin hook

  const { data: userInfoData, loading: userInfoLoading, error: userInfoError } = useGetUserInfo();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const { data: assignmentData, refetch: refetchAssignments } = useGetAssignments();

  const onLoginClick = () => {
    setError(null);
    login((email || "").toLowerCase(), password); // Call the login function with email and password
  };

  useEffect(() => {
    if (loginData && loginData.login.token) { // on successful login
      // console.log(loginData);
      navigateAfterLogin(loginData.login.user);
    }
  }, [loginData, navigate]);

  useEffect(() => {
    if (userInfoData && userInfoData.getUserInfo) {
      // console.log('user info: ' + userInfoData.getUserInfo);
      navigateAfterLogin(userInfoData.getUserInfo);
    }
  }, [userInfoData, navigate]);

  const navigateAfterLogin = async ( userData ) => {
    try {
      const { data: refetchedAssignmentData } = await refetchAssignments();
      // console.log('Refetched assignment data:', refetchedAssignmentData);
      if ( userData ) {
        const { role, subjects, grades } = userData;
        // console.log('role: ' + role); console.log('subjects: ' + subjects); console.log('grades: ' + grades);
  
        if ( !role || !subjects || !grades || role.length === 0 || subjects.length === 0 || grades.length === 0 ) {
          navigate("/profile"); // if user hasn't set their profile, first navigate to profile
          return;
        }
      }
  
      if ( refetchedAssignmentData && refetchedAssignmentData.getAssignments.length > 0 && refetchedAssignmentData.getAssignments.some((assignment) => assignment.status === 'DONE' )) {
        navigate("/assignments"); // if user has assignments, navigate to all assignments
      } else {
        navigate("/scan"); // if user doesn't have any assignments, navigate to scan
      }
    } catch (error) {
      console.error('Error refetching assignment data:', error);
    }
  };

  // useEffect(() => { console.log('assignment data : ' + assignmentData); }, []);

  useEffect(() => {
    if (loginError) {
      // console.error('Login error:', loginError);
      // Handle login errors here, e.g., display a message to the user
      setError('password');
    }
  }, [loginError]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        onLoginClick();
    }};

  document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }; }, [onLoginClick]);

  const onSignUpClick = () => {
    navigate("/");
  };

  const onResetClick = () => {
    navigate("/forgot-password");
  };

  return (
      <SignUpRoot>
        <SignUpParent>
          <SignUp1>Log In</SignUp1>
          <Input1>
            <Input
                placeholder="Email"
                propMinWidth="182px"
                icon={'mail'}
                onChange={(x) => setEmail(x)}
            />
            <Input
                placeholder="Password"
                icon={'lock'}
                type={"password"}
                onChange={(x) => setPassword(x)}
                errorMessage={error == 'password' ? "Invalid email or password." : ''}
            />
          </Input1>
        <ButtonContainer>
          <ButtonFixed text="Log In" onClick={onLoginClick} />
        </ButtonContainer>
        <ForgotContainer>
          <ForgotText>Forgot your password?</ForgotText>
          <ButtonLink text="Reset" onClick={onResetClick} />
        </ForgotContainer>
        </SignUpParent>
        <BottomFixed>
          <BottomContainer>
            <FooterText>Don't have an account?</FooterText>
            <ButtonLink text="Sign Up" onClick={onSignUpClick} />
          </BottomContainer>
        </BottomFixed>
      </SignUpRoot>
  );
};

export default LogIn;
