import React, { FunctionComponent, useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import Button from "../components/basic/Button";
import Fonts from "../components/basic/common/Fonts";
import Colors from "../components/basic/common/Colors";
import ImageGrid from '../components/ImageGrid';
import { useGetAssignment } from '../apollo/useAssignments';
import { useDeleteImages } from '../apollo/useImageUpload';
import { useNavigate, useParams } from 'react-router-dom';

const DeleteImagesRoot = styled.div`
    width: 100%;
    height: 100svh;
    position: relative;
    background-color: ${Colors.pale};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    padding: 0px 0px 0px 0px;
    overflow-x: hidden;
    overflow-y: hidden;
`;

const TopBarContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    z-index: 10;
    padding: 5px 5px 10px 5px;
`;
const TopLeft = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-start;
    padding: 0px 0px 0px 0px;
`;
const TopCenter = styled.div`
    flex: 2;
    display: flex;
    justify-content: center;
    font-weight: ${Fonts.outfitRegular.fontWeight};
    font-family: ${Fonts.outfitRegular.fontFamily};
    font-size: 16px;
    line-height: 20px;
`;
const TopRight = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding: 0px 0px 0px 0px;
`;

const ScrollableContainer = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    overflow-y: auto;
    gap: 10px;
    margin: 0px 0px 0px 0px;
    padding: 0px 10px 10px 10px;
`;

const DeleteImages: FunctionComponent = () => {
    const navigate = useNavigate();

    const { assignmentId } = useParams();

    const { deleteImages } = useDeleteImages();

    const { data, loading, error, refetch } = useGetAssignment(assignmentId);
    const [ imagesList, setImages] = useState([]);

    useEffect(() => {
        if (data && data.getAssignment) {
        const unassignedImages = data.getAssignment.images.filter(image => !image.studentAssignmentId); // filter out scored images
        const formattedImages = unassignedImages.map(image => ({ 
            img: image.url,
            name: 'uploaded image',
            id: image.id
        }));
        setImages(formattedImages);
    } }, [data]);

    const [selectedImageIds, setSelectedImageIds] = useState<string[]>([]);
    const handleUpdateSelectedImageIds = (ids: string[]) => {
        setSelectedImageIds(ids);
    };

    const onBackClick = useCallback(() => {
        let navigationState = {};
        if (imagesList.length > 0) {
            const lastImageThumbnail = imagesList[imagesList.length - 1]?.img;
            const numImages = imagesList.length;
            navigationState = { lastImageThumbnail, numImages }; // pass the last image's URL, and the number of images
        }
        navigate(`/scan/${assignmentId}`, { state: navigationState });
    }, [navigate, selectedImageIds, imagesList, assignmentId]);

    const onDeleteClick = useCallback(async () => {
        await deleteImages(selectedImageIds, assignmentId); // actually delete the selected images in the backend
        
        const updatedImagesList = imagesList.filter(image => !selectedImageIds.includes(image.id)); // filter out the deleted images from the imagesList
        setImages(updatedImagesList); // update the state with the filtered images list

        let navigationState = {};
        if (updatedImagesList.length > 0) {
            const lastImageThumbnail = updatedImagesList[updatedImagesList.length - 1]?.img;
            const numImages = updatedImagesList.length;
            navigationState = {lastImageThumbnail, numImages}; // update navigation state with new values
        }
        setSelectedImageIds([]); // reset selectedImageIds after deletion

        navigate(`/scan/${assignmentId}`, {state: navigationState});
    }, [navigate, selectedImageIds, imagesList, assignmentId, deleteImages]);

    return (
        <DeleteImagesRoot>
            <TopBarContainer>
                <TopLeft><Button color={'blue'} icon="left" onClick={onBackClick}/></TopLeft>
                <TopCenter>Select images.</TopCenter>
                <TopRight>
                    <Button color={'red'}
                        icon="delete-fill" 
                        text={selectedImageIds.length > 0 ? "Delete " + selectedImageIds.length : "Delete"} 
                        onClick={onDeleteClick} 
                        disabled={selectedImageIds.length === 0 ? true : undefined}/>
                </TopRight>
            </TopBarContainer>
            <ScrollableContainer>
                <ImageGrid images={imagesList} onUpdateSelectedImageIds={handleUpdateSelectedImageIds} color={'red'} />
            </ScrollableContainer>
        </DeleteImagesRoot>
    );
};

export default DeleteImages;