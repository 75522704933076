// useImageUpload.js
import { useState, useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import {GET_ASSIGNMENT_QUERY} from "./useAssignments";

// GraphQL mutation to request an S3 image upload URL
const REQUEST_IMAGE_UPLOAD_URL_MUTATION = gql`
  mutation requestImageUploadURL($assignmentId: ID) {
    requestImageUploadURL(assignmentId: $assignmentId) {
      url
      imageId
      assignmentId
    }
  }
`;

// GraphQL mutation to confirm the image has been uploaded
const CONFIRM_IMAGE_UPLOAD_MUTATION = gql`
  mutation confirmImageUpload($imageId: ID!) {
    confirmImageUpload(imageId: $imageId) {
      id
      assignmentId
      uploadDate
      imageS3Id
      size
      resolution
    }
  }
`;


export function useImageUpload() {
    const [requestImageUploadURLMutation] = useMutation(REQUEST_IMAGE_UPLOAD_URL_MUTATION);
    const [confirmImageUploadMutation] = useMutation(CONFIRM_IMAGE_UPLOAD_MUTATION);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const assignmentId = useRef(null);
    const uploadInProgress = useRef(false);
    const uploadCounter = useRef(0);
    const uploadQueue = useRef([]);

    const updateLoadingState = () => {
        setLoading(uploadCounter.current > 0);
    };
    // Function to handle the actual upload process
    const processUpload = async (file, assignmentIdForUpload) => {
        uploadCounter.current += 1; // Increment upload counter
        updateLoadingState();
        setError('');

        try {
            // Request an S3 Upload URL
            const { data: requestData } = await requestImageUploadURLMutation({ variables: { assignmentId: assignmentIdForUpload } });
            if (!requestData || !requestData.requestImageUploadURL) {
                throw new Error("Failed to get an upload URL");
            }

            const { url, imageId, assignmentId: newAssignmentId } = requestData.requestImageUploadURL;
            // If this is the first upload and assignmentId is not set yet, use the new one
            if (!assignmentId.current) {
                assignmentId.current = newAssignmentId;
            }

            // Upload the Image to S3
            const uploadResponse = await fetch(url, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': 'image/png', // Adjust based on your file type
                },
            });

            if (!uploadResponse.ok) {
                throw new Error('Failed to upload image to S3');
            }

            // Confirm the Upload
            const { data: confirmData } = await confirmImageUploadMutation({
                variables: { imageId },
                refetchQueries: [
                    {
                        query: GET_ASSIGNMENT_QUERY, // Make sure to define this query or use the correct query to refetch the images
                        variables: { assignmentId: assignmentId.current }, // Add any necessary variables for the refetch query
                    }
                ]
            });
            if (!confirmData || !confirmData.confirmImageUpload) {
                throw new Error("Failed to confirm image upload");
            }

            uploadCounter.current -= 1; // Decrement upload counter on success
            updateLoadingState();
            return {
                success: true,
                imageId: imageId,
                assignmentId: newAssignmentId,
                message: "Image uploaded and confirmed successfully",
            };
        } catch (error) {
            uploadCounter.current -= 1; // Decrement upload counter on success
            updateLoadingState();
            setError(error.message || 'An error occurred during the image upload process');
            return {
                success: false,
                message: error.message || 'An error occurred during the image upload process',
            };
        }
    };

    // Function to manage uploads
    const uploadImage = async (providedAssignmentId, file) => {
        // If an assignmentId is already set or provided, upload immediately
        if (providedAssignmentId || assignmentId.current) {
            const currentAssignmentId = providedAssignmentId || assignmentId.current;
            return await processUpload(file, currentAssignmentId);
        } else if (uploadInProgress.current) {
            // If an upload is already in progress, add to queue
            return new Promise((resolve, reject) => {
                const callback = async (upToDateAssignmentId) => {
                    try {
                        const uploadResult = await processUpload(file, upToDateAssignmentId);
                        resolve(uploadResult);
                    } catch (error) {
                        reject(error);
                    }
                };

                uploadQueue.current.push({ assignmentId: providedAssignmentId, file, callback });
            });
        } else {
            // Handle the first upload
            uploadInProgress.current = true;
            const uploadResult = await processUpload(file, providedAssignmentId);
            uploadInProgress.current = false;

            // Process the next items in the queue, if any
            while (uploadQueue.current.length > 0) {
                const { assignmentId: responseAssignmentId, file: nextFile, callback } = uploadQueue.current.shift();
                const currentAssignmentId = providedAssignmentId || assignmentId.current;
                await callback(currentAssignmentId); // Call the callback function to process the next upload
            }

            return uploadResult;
        }
    };

    return { uploadImage, loading, error };
}


const DELETE_IMAGES_MUTATION = gql`
  mutation deleteImages($imageIds: [ID!]!) {
    deleteImages(imageIds: $imageIds)
  }
`;

export function useDeleteImages() {
    const [deleteImagesMutation] = useMutation(DELETE_IMAGES_MUTATION);

    const deleteImages = (imageIds, assignmentId) =>
        deleteImagesMutation({
            variables: { imageIds },
            refetchQueries: [
                // Optionally refetch queries to update the UI with new data
                {
                    query: GET_ASSIGNMENT_QUERY,
                    variables: { assignmentId },
                },
                // You can include other queries here if needed
            ],
        });

    return { deleteImages };
}

