import React, { FunctionComponent, useCallback, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Input from "../components/basic/Input";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useRenameAssignment } from '../apollo/useAssignments';
import { useGetUserInfo } from '../apollo/useAuth'; // Import the new hook
import Button from "../components/basic/Button";
import PositionedLayout from "../components/PositionedLayout";
import Colors from "../components/basic/common/Colors";
import Fonts from "../components/basic/common/Fonts";
import { useGetAssignment } from "../apollo/useAssignments";

const ProcessingRoot = styled.div`
    width: 100%;
    height: 100svh;
    position: relative;
    background-color: ${Colors.pale};
    overflow: hidden; /* Keep this to prevent scrolling */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    text-align: center;
    font-size: 12px;
    color: ${Colors.black};
    font-family: ${Fonts.outfitRegular.fontFamily};
`;

const Scoring = styled.h1`
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 48px;
  font-weight: 700;
  font-family: inherit;
`;
const ResultsWillBe = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`;
const EmailFrame = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100svh - 50px - 40px); // 100svh - top bar height - bottom bar height
  justify-content: center;
  padding: 0px 15px 21px;
  gap: 15px;
  text-align: center;
  font-size: 40px;
  color: ${Colors.black};
  font-family: ${Fonts.outfitRegular.fontFamily};
  background-color: ${Colors.pale};
  z-index: 1;
`;
const FeelFreeTo = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
`;

const BottomFixed = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    bottom: 0px;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 10px 10px 10px 10px;
    max-width: 500px;
`;
const BottomTextContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;'
`;

const Processing: FunctionComponent = () => {
    const navigate = useNavigate();

    const { renameAssignment } = useRenameAssignment();

    const { assignmentId } = useParams<{ assignmentId: string }>();
    const { data: userData } = useGetUserInfo();
    const { data, loading, error, refetch } = useGetAssignment(assignmentId);
    const [assignmentName, setAssignmentName] = useState('');

    useEffect(() => {
        if (data && data.getAssignment) {
            setAssignmentName(data.getAssignment.name);
            // console.log(data.getAssignment.status);
            // status = OPEN, IN_PROCESS, DONE 
        }
    }, [data]);

    useEffect(() => {
        if (data && data.getAssignment && data.getAssignment.status === 'DONE') {
            navigate(`/review-scores/${assignmentId}`);
        }
    }, [data, assignmentId]);

    const handleInputChange = (value: string) => {
        setAssignmentName(value);
    };

    // Handle the input losing focus
    const handleInputBlur = () => {
        // Use the assignmentId from the URL instead of a hardcoded value
        if (assignmentId) { // Check if assignmentId is not undefined
            renameAssignment(assignmentId, assignmentName).catch(console.error);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.currentTarget.blur(); // Trigger blur to handle rename
        }
    };

    const onCloseClick = useCallback(() => {
        navigate("/assignments");
    }, [navigate]);

    return (
        <ProcessingRoot>
            <PositionedLayout
                leftComponent={
                    <Button color={'blue'} onClick={onCloseClick} icon={'home'} />}
                centerComponent={
                    <div style={{ margin: '0px 0px 8px 0px', maxHeight: '30px', width: '100%' }}><Input
                    small={true}
                    value={assignmentName}
                    onChange={handleInputChange} // Update value on change
                    onBlur={handleInputBlur} // Call renameAssignment on blur
                    onKeyDown={handleKeyDown}
                /></div>}
                rightComponent={<div style={{ width: '40px', height: '40px' }}></div>}
            />
            <EmailFrame>
                <Scoring>SCORING...</Scoring>
                <ResultsWillBe>
                    This might take a while. We'll let you know at {userData?.getUserInfo?.email ?? 'your email'} when this assignment is scored.
                </ResultsWillBe>
                <CircularProgress size={100} />
            </EmailFrame>
            <BottomFixed>
                <BottomTextContainer>
                    <FeelFreeTo>{`Feel free to close this page. Scoring will continue in the background.`}</FeelFreeTo>
                </BottomTextContainer>
            </BottomFixed>
        </ProcessingRoot>
    );
};

export default Processing;

