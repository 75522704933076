import React, { FunctionComponent, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Colors from './common/Colors';
import Fonts from './common/Fonts';
import Button from './Button';
import ButtonLink from './ButtonLink';
import _ from 'lodash';

const Overlay = styled.div`
    width: 100vw;
    height: 100svh;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.4);
    overflow: hidden;
    z-index: 99;
`;

const ModalBackground = styled.div`
    background-color: ${Colors.white};
    width: 302px;
    max-width: calc(100% - 20px);
    border-radius: 14px; 
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    display: flex;
    flex-direction: column; 
    align-items: flex-start;
    justify-content: center; 
    padding: 20px 20px 20px 20px;
    box-shadow: -2px 6px 6px rgba(0,0,0,0.1);
    z-index: 100;
`;

const TextContainer = styled.div`
    width: 100%;  
    display: flex;
    flex-direction: column; 
    align-items: flex-start;
    justify-content: center; 
    gap: 10px;
    margin: auto; 
`;
const TitleText = styled.div`
    width: 100%;    
    font-weight: ${Fonts.outfitBold.fontWeight};
    font-family: ${Fonts.outfitBold.fontFamily};
    font-size: 14px;
    line-height: 28px;
    color: ${Colors.grayMed};
`;
const BodyText = styled.div`
    width: 100%;
    font-weight: ${Fonts.outfitRegular.fontWeight};
    font-family: ${Fonts.outfitRegular.fontFamily};
    font-size: 14px;
    line-height: 18px; 
    color: ${Colors.grayMed};
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: flex-end; 
    margin: 20px 0px 0px 0px;
    gap: 10px;
`;

export type MessageModalType = {
    titleText: string;
    bodyText: string | string[];
    primaryButtonText: string;
    primaryButtonIcon?: string | null;
    primaryButtonColor?: string;
    secondaryButtonText: string;
    showOverlay?: boolean | null;
    onClick: () => void;
    onClose: () => void;
};

const modalRef = React.createRef<HTMLDivElement>();

const MessageModal: FunctionComponent<MessageModalType> = ({
    titleText,
    bodyText,
    primaryButtonText,
    primaryButtonIcon,
    primaryButtonColor = 'blue',
    secondaryButtonText,
    showOverlay,
    onClick,
    onClose,
 }) => {

    const handlePrimaryClick = _.debounce(() => {
        onClick(); // take the primary click action
    }, 500);

    const handleClose = _.debounce(() => {
        onClose(); // take the close action
    }, 500);

    useEffect(() => {
        const handleClickOutside = (event) => { // click outside the modal to close it
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleClose(); 
        } };
    
        // delay adding the event listener so it doesn't immediately close on opening
        const enableClickOutside = () => {
            document.addEventListener("mousedown", handleClickOutside);
        };
        const timer = setTimeout(enableClickOutside, 100);
    
        return () => {
            clearTimeout(timer);
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open, handleClose, handlePrimaryClick]);

    return ( 
        <div> {showOverlay && <Overlay></Overlay>}
        <ModalBackground ref={modalRef}>
            <TextContainer>
                <TitleText>{titleText}</TitleText>
                {Array.isArray(bodyText) ? (
                bodyText.map((text, index) => (
                    <BodyText key={index}>{text}</BodyText>
                ))
                ) : (
                <BodyText>{bodyText}</BodyText>
                )}
            </TextContainer>
            <ButtonContainer>
                <ButtonLink text={secondaryButtonText} onClick={handleClose} />
                <Button text={primaryButtonText} icon={primaryButtonIcon} color={primaryButtonColor} onClick={handlePrimaryClick} />
            </ButtonContainer>
        </ModalBackground>
        </div>
    ); }

export default MessageModal;