import React, {FunctionComponent} from "react";
import styled from 'styled-components';
import Colors from "./common/Colors";
import CircularProgress from '@mui/material/CircularProgress';

const LoadingRoot = styled.div`
    width: 100%;
    height: 100svh;
    position: relative;
    background-color: ${Colors.pale};
    overflow: hidden; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Loading: FunctionComponent = () => {

    return (
        <LoadingRoot> 
            <CircularProgress size={100} />
        </LoadingRoot>
    );
};
    
export default Loading;