import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Icon from './common/Icon';
import Colors from './common/Colors';
import Fonts from './common/Fonts';

// Since we're directly using TextField, we no longer need styled-components for styling.
// Instead, we use the sx prop for custom styles.



export type InputType = {
    inputType?: string;
    icon?: string;
    type?: string;
    small?: boolean;
    ghost?: boolean;
    width?: string;
    value?: string | number | readonly string[] | undefined;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    errorMessage?: string;
    placeholder?: string;
    autoFocus?: boolean;
};

const Input: React.FC<InputType> = ({
    inputType,
    width = '100%', // Default width is 100%
    icon,
    type = 'text', // Default to 'text' if not provided
    small = false,
    ghost = false,
    value,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    errorMessage,
    placeholder,
    autoFocus = false,
}) => {
    
    const [isActive, setIsActive] = useState(false);
    const iconColor = errorMessage ? Colors.red : (isActive ? Colors.blue : Colors.grayDark);

    const parentRef = useRef<HTMLDivElement>(null);
    const [parentWidth, setParentWidth] = useState<number | undefined>(undefined);
    useEffect(() => {
        const handleResize = () => {
          if (parentRef.current) {
            setParentWidth(parentRef.current.offsetWidth);
        } };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, [value]);

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (autoFocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [autoFocus]);

    const [inputWidth, setInputWidth] = useState('146px');
    useEffect(() => {
        const padding = 40;
        const minWidth = 146;
        const maxWidth = parentWidth || minWidth;
        const textWidth = getTextWidth(value as string, small ? 16 : 18, Fonts.outfitRegular.fontWeight, Fonts.outfitRegular.fontFamily) + padding;
        // console.log('text width: ' + textWidth);
    
        let calculatedWidth = Math.max(minWidth, Math.min(textWidth, maxWidth));
        if (parentWidth && parentWidth < parseInt(inputWidth)) {
            calculatedWidth = Math.max(minWidth, Math.min(textWidth, parentWidth));
        }
        // setInputWidth(`${calculatedWidth}px`);
        setInputWidth(`${minWidth}px`);

        // console.log('parent width: ' + parentWidth);
        // console.log('input width: ' + inputWidth);
    }, [parentWidth, value, small, inputWidth]);

    const getTextWidth = (text: string, fontSize: number, fontWeight: number, fontFamily: string) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        if (context) {
            context.font = `${fontWeight} ${fontSize}px ${fontFamily}`;
            const metrics = context.measureText(text);
            return metrics.width;
        }
        return 0;
    };

    return (
        <div ref={parentRef} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <TextField
            value={value}
            onChange={(event) => {
                const newValue = event.target.value; // Get the current text value from the event
                if (onChange) onChange(newValue); // Call the provided onChange prop with the new value
            }}
            onFocus={(event) => {
                setIsActive(true);
                // Optionally call onFocus with the current value if needed
                if (onFocus) onFocus(event.target.value);
            }}
            onBlur={(event) => {
                setIsActive(false);
                // Optionally call onBlur with the current value if needed
                if (onBlur) onBlur(event.target.value);
            }}
            variant="outlined"
            type={type}
            sx={{
                width: small ? inputWidth : width, // apply dynamic width to small input
                paddingBottom: errorMessage ? '0px' : '16px', // adjust bottom padding based on error state
                "& .MuiInputBase-root": {
                    height: small ? '36px' : '52px',
                    backgroundColor: ghost ? 'transparent' : Colors.white,
                    paddingLeft: small ? '10px' : '16px',
                    borderRadius: "121px",
                    fontSize: small ? "16px" : "18px",
                    lineHeight: small ? "22px" : "28px",
                    display: 'flex',
                    alignItems: 'center',
                    fontFamily: Fonts.outfitRegular.fontFamily,
                    fontWeight: Fonts.outfitRegular.fontWeight,
                    color: Colors.grayDark,

                    // ghost styling
                    "& .MuiOutlinedInput-notchedOutline": ghost ? { border: "none", } : {},
                    "&:hover .MuiOutlinedInput-notchedOutline": ghost ? { border: "1px solid #CECECE", } : {},
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": { border: "2px solid #1456F0", },
                },
                "& .MuiInputBase-input": {
                    paddingLeft: "4px",
                    paddingTop: small ? "calc((36px - 22px) / 2)" : "calc((52px - 28px) / 2)",
                    paddingBottom: small ? "calc((36px - 22px) / 2)" : "calc((52px - 28px) / 2)",
                    "&::placeholder": {
                        color: Colors.grayMed,
                        opacity: 1, // Ensure the placeholder is fully visible
                    },
                },
                "& .MuiFormHelperText-root": {
                    marginLeft: "22px",
                    fontFamily: Fonts.outfitRegular.fontFamily,
                    fontWeight: Fonts.outfitRegular.fontWeight,
                    fontSize: "10px",
                    marginTop: "0px",
                    color: Colors.red,
                },
            }}
            InputProps={{
                startAdornment: icon ? <Icon icon={icon} color={iconColor} size={small? 20 : 24} /> : null,
                inputRef: inputRef,
            }}
            error={!!errorMessage}
            helperText={errorMessage}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
        /></div>
    );
};

export default Input;
