import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Fonts from "../components/basic/common/Fonts";
import Colors from "../components/basic/common/Colors";
import Button from "./basic/Button";

const ErrorRoot = styled.div`
    height: 100svh;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: ${Colors.pale};
`;

const CenterContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 10px 10px 10px 10px;
    max-width: 420px;
`;
const ErrorHeader = styled.h1`
    position: relative;
    font-color: ${Colors.grayDark};
    font-weight: ${Fonts.outfitBold.fontWeight};
    font-family: ${Fonts.outfitBold.fontFamily};
    font-size: 48px;
    line-height: 48px;
    margin: 0px 0px 20px 0px;
`;
const ErrorIllustration = styled.img`
    margin: 0px 0px 20px 0px;
`;
const ErrorMessage = styled.div`
    position: relative;
    font-color: ${Colors.grayDark};
    font-weight: ${Fonts.outfitRegular.fontWeight};
    font-family: ${Fonts.outfitRegular.fontFamily};
    font-size: 16px;
    line-height: 20px;
    margin: 0px 0px 20px 0px;
`;
const ErrorButton = styled.div`
    position: relative;
    margin: 0px 0px 0px 0px;
`;

const ErrorScreen: FunctionComponent = () => {

    const refreshPage = () => {
        window.location.reload();
    };

    return (
        <ErrorRoot>
            <CenterContainer>
                <ErrorHeader>Uh oh!</ErrorHeader>
                <ErrorIllustration src={`${process.env.PUBLIC_URL}/cat-sleeping-illustration.svg`} />
                <ErrorMessage>Sorry, something went wrong.</ErrorMessage>
                <ErrorButton><Button color={'blue'} text={'Refresh'} icon={'reset'} onClick={refreshPage}/></ErrorButton>
            </CenterContainer>
        </ErrorRoot>
    );
};

export default ErrorScreen;