import { FunctionComponent, useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';

type IconProps = {
    icon: 'arrow-right' | 'camera' | 'check' | 'delete' | 'down' | 'up' | 'exclamation' | 
        'info' | 'key' | 'lock' | 'mail' | 'page' | 'person' | 'plus' | 'question' | 
        'scan' | 'table' | 'upload' | 'x' | 'home' | 'logout' | 'page-outline' | 'delete-fill' | 
        'check-fill' | 'logo' | 'left' | 'loading' | 'reset' | 'expand' | 'right';
    color: string; // Hex color code
    size: number; // Size in pixels
    onClick: () => void;
};

const IconWrapper = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => prop !== 'rotate' && defaultValidatorFn(prop),
  })<{ size: number, rotate?: boolean }>`
    height: ${({ size }) => `${size}px`};
    width: ${({ size }) => `${size}px`};
    display: inline-flex;

    svg {
        height: 100%;
        width: 100%;
        pointer-events: none;
        animation: ${({ rotate }) => rotate ? css`${rotateAnimation} 6s linear infinite` : 'none'};
    }
`;

const rotateAnimation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Icon: FunctionComponent<IconProps> = ({ icon, color, size , onClick = () => {}}) => {
    const [svgContent, setSvgContent] = useState('');

    const doOnClick = () => {
        onClick()
    }

    useEffect(() => {
        fetchSvgContent(icon).then(svg => {
            const updatedSvg = svg.replace(/fill="[^"]*"/g, `fill="${color}"`); // Replace the fill color
            setSvgContent(updatedSvg);
        });
    }, [icon, color]);

    return (
        <IconWrapper onClick={doOnClick} size={size} rotate={icon === 'loading' ? true : undefined} dangerouslySetInnerHTML={{ __html: svgContent }} />
    );
};

export default Icon;

async function fetchSvgContent(icon) {
    const response = await fetch(`/icons/icon-${icon}.svg`);
    return await response.text();
}
