import React, {FunctionComponent, useRef, useState, useEffect, useCallback} from "react";
import _ from "lodash";
import FrameTopBar from "../components/FrameTopBar";
import FrameBottomBar from "../components/FrameBottomBar";
import Scanner from "../components/Scanner";
import { useScoreAssignment, useGetAssignment } from "../apollo/useAssignments";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import UploadZone from "../components/UploadZone";
import Icon from "../components/basic/common/Icon";
import Fonts from "../components/basic/common/Fonts";
import Colors from "../components/basic/common/Colors";
import { useLocation } from 'react-router-dom';
import { useGetUserInfo } from "../apollo/useAuth";
import cameraSound from "../assets/sound-effects/camera-snap.mp3";
import MessageAlert from "../components/basic/MessageAlert";
import MessageModal from "../components/basic/MessageModal";

const ScanRoot = styled.div`
    display: grid;
    grid-template-rows: 1fr auto; /* Removed 'auto' for the top bar row */
    height: 100svh;
    width: 100%;
    background-color: ${Colors.pale};
    overflow: hidden;
    box-sizing: border-box;
    position: relative; /* Added for absolute positioning context */
`;
const TopBarContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px; /* Adjust based on the actual height of your FrameTopBar */
    z-index: 10; /* Ensure it's above other content */
    background-color: ${props => props.mode === 'upload' ? Colors.pale : 'transparent'};
`;

const OuterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: ${props => props.mode === 'upload' ? 'flex-start' : 'center'}; 
    width: 100%;
    position: relative; 
    overflow-y: auto;
`;
const ContentContainer = styled.div`
    align-items: center;
    max-width: 100%;
`;

const UploadContainer = styled.div`
    width: 100%;
    padding: 20px 10px;
`;
const UploadWrapper = styled.div`
    position: relative;
    padding: 40px 0px 0px 0px;
    width: 100%;
`;

const FramesContainer1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 10px 0px;
`;

const AssignmentName = styled.input`
  border: none;
  outline: none;
  font-weight: ${Fonts.outfitRegular.fontWeight};
  font-family: ${Fonts.outfitRegular.fontFamily};
  font-size: 14px;
  line-height: 18px;
  background-color: transparent;
  height: 22px;
  flex: 1;
  position: relative;
  color: ${Colors.grayMed};
  text-align: left;
  display: inline-block;
  min-width: 68px;
  z-index: 1;
`;

const IconParent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
`;
const Assignment = styled.div`
  flex: 1;
  border-radius: 4px;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.grayLight};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 13px;
`;
const AssignmentInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 10px 12px 10px;
  box-sizing: border-box;
`;
const Panel = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: 2;
`;

const Corner = styled.div`
    position: absolute;
    width: 20px; // this is the length of the corner lines
    height: 20px; // this is the length of the corner lines
    border: 4px solid #70A0FF; // GUIDE_COLOR and GUIDE_WIDTH
    z-index: 15;
`;

const MessageAlertWrapper = styled.div`
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 100;
`;

const MainZone: FunctionComponent = ({ mode, aspectRatioState, scannerRef, assignmentId, imageUploaded, 
        handleAspectRatioChange, handleNewThumbnail, setUploadLoading, dropzoneRef, handleNewFile, 
        filenames, onCameraStart }) => {

    const [bgFrame, setBGFrame] = useState<string | null>(null);

    const [currentBgOpacity, setCurrentBgOpacity] = useState(1); // Opacity for the current background
    const [newBgOpacity, setNewBgOpacity] = useState(0); // Opacity for the new background

    const [currentBg, setCurrentBg] = useState(null); // Stores the URL of the current background
    const [newBg, setNewBg] = useState(null); // Stores the URL of the current background

    const [containerStyle, setContainerStyle] = useState({ width: '100%', height: '100%' });
    const [containerDims, setContainerDims] = useState({ width: '100%', height: '100%' });
    const [cameraStarted, setCameraStarted] = useState(false);

    const [showFailedUploadMessage, setFailedUploadMessage] = useState(false);

    const [uploadStatuses, setUploadStatuses] = useState({});
    const handleFileUploadStart = (filename) => {
        setUploadStatuses(prevStatuses => ({ ...prevStatuses, [filename]: 'uploading' }));
    };
    const handleFileUploadComplete = (filename) => {
        setUploadStatuses(prevStatuses => ({ ...prevStatuses, [filename]: 'completed' }));
    };
    const handleFileUploadFailed = (filename) => {
        setUploadStatuses(prevStatuses => ({ ...prevStatuses, [filename]: 'failed' }));
        if (showFailedUploadMessage) {
            setFailedUploadMessage(false);
        }
        setTimeout(() => setFailedUploadMessage(true), 0);
    };

    const onFailedUploadMessageClose = () => {
        setFailedUploadMessage(false);
    };
    // useEffect(() => { console.log(`Failed upload message visibility: ${showFailedUploadMessage}`); }, [showFailedUploadMessage]);    

    const preloadImage = (src) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = resolve;
            img.onerror = reject;
        })
    };

    useEffect(() => {
        if (bgFrame && newBgOpacity == 0  && currentBgOpacity == 1) {
            // Assume bgFrame is the data URL for the new background
            preloadImage(bgFrame).then(() => {
                // Fade in new background
                setNewBg(bgFrame); // Update the newBg state to the loaded image
                setNewBgOpacity(0.9); // Start the fade-in for the new background
                setCurrentBgOpacity(0);

                // After the new background has faded in, update the current background to match the new
                // and reset the opacities for the next transition
                setTimeout(() => {
                    setCurrentBg(bgFrame); // The new background becomes the current
                    setCurrentBgOpacity(0.9); // Ensure the current background is fully visible
                    setNewBgOpacity(0); // Hide the new background layer to prepare for the next transition
                    // setNewBg(null); // Reset newBg as it's now the currentBg
                }, 2000); // This duration should match the CSS transition duration
            }).catch(error => console.error("Error preloading image:", error));
        }
    }, [bgFrame]);

    useEffect(() => {
        const adjustScannerSize = () => {
            const aspectRatio = aspectRatioState || (16 / 9);
            const windowHeight = window.innerHeight;
            const windowWidth = window.innerWidth;
            const maxScannerHeight = windowHeight - 72; // Adjusted based on the overlay
            const maxScannerWidth = windowWidth;

            if (maxScannerWidth > maxScannerHeight * aspectRatio) {
                setContainerStyle({
                    width: `${maxScannerHeight * aspectRatio}px`,
                    height: `${maxScannerHeight}px`
                });
                setContainerDims({
                    width: maxScannerHeight * aspectRatio,
                    height: maxScannerHeight
                });
            } else {
                setContainerStyle({
                    width: `${maxScannerWidth}px`,
                    height: `${maxScannerWidth / aspectRatio}px`
                });
                setContainerDims({
                    width: maxScannerWidth,
                    height: maxScannerWidth / aspectRatio
                });
            }
        };

        adjustScannerSize();
        window.addEventListener('resize', adjustScannerSize);

        return () => window.removeEventListener('resize', adjustScannerSize);
    }, [aspectRatioState]);

    const ScannerGuide = ({ guideMargin, aspectRatio, containerWidth, containerHeight }) => {

        let aspectRatioCalc = aspectRatio = aspectRatio.width / aspectRatio.height;
        let verticalMargin = guideMargin;
        let horizontalMargin = guideMargin

        //Width controls
        if (containerWidth < (containerHeight * aspectRatio)) {
            verticalMargin = (containerHeight - ((containerWidth - (guideMargin * 2)) / aspectRatioCalc)) / 2;
        } else { //Height controls
            horizontalMargin = (containerWidth - ((containerHeight - (guideMargin * 2)) * aspectRatioCalc)) / 2;
        }

        return (
            <>
                <Corner style={{ top: verticalMargin, left: horizontalMargin, borderRight: 'none', borderBottom: 'none' }} />
                <Corner style={{ top: verticalMargin, right: horizontalMargin, borderLeft: 'none', borderBottom: 'none' }} />
                <Corner style={{ bottom: verticalMargin, left: horizontalMargin, borderRight: 'none', borderTop: 'none' }} />
                <Corner style={{ bottom: verticalMargin, right: horizontalMargin, borderLeft: 'none', borderTop: 'none' }} />
            </>
        );
    };

        return <OuterContainer mode={mode}>
            {(() => {
                if (mode == 'scan') {
                    return <div style={{
                        margin: 0,
                        padding: 0,
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden'
                    }}>
                        {/* Current background */}
                        <div style={{
                            position: 'absolute',
                            width: 'calc(100% + 160px)', /* Extend width */
                            height: 'calc(100% + 160px)', /* Extend height */
                            top: '-80px', /* Offset the extension */
                            left: '-80px', /* Offset the extension */
                            backgroundSize: 'cover',
                            filter: 'blur(40px)',
                            backgroundImage: `url('${currentBg}')`,
                            opacity: currentBgOpacity,
                            transition: 'opacity 2.0s ease-in-out',
                        }}/>

                        {/* New background */}
                        <div style={{
                            position: 'absolute',
                            width: 'calc(100% + 160px)', /* Extend width */
                            height: 'calc(100% + 160px)', /* Extend height */
                            top: '-80px', /* Offset the extension */
                            left: '-80px', /* Offset the extension */
                            backgroundSize: 'cover',
                            filter: 'blur(40px)',
                            backgroundImage: `url('${newBg}')`,
                            opacity: newBgOpacity,
                            transition: 'opacity 2.0s ease-in-out',
                        }}/>
                    </div>;
                }
                return <span/>
            })()}
            <ContentContainer style={containerStyle}>
                {(() => {
                    if (mode == 'scan') {
                        return <div style={{position: 'relative', width: '100%', height: '100%'}}>
                            <Scanner
                                ref={scannerRef}
                                assignmentId={assignmentId}
                                imageUploaded={imageUploaded}
                                onAspectRatioChange={handleAspectRatioChange}
                                onNewThumbnail={handleNewThumbnail}
                                onNewBGFrame={setBGFrame}
                                uploadLoadingUpdated={setUploadLoading}
                                onCameraStart={() => {
                                    // console.log('Camera started');
                                    setCameraStarted(true)
                                    onCameraStart()
                                }}
                            />
                            {cameraStarted && <ScannerGuide guideMargin={50} aspectRatio={containerDims.height > containerDims.width ? {width: 8.5, height: 11} : {width: 11, height: 8.5}} containerWidth={containerDims.width} containerHeight={containerDims.height}/>
                            }</div>
                    }
                    if (mode == 'upload') {
                        return <UploadContainer>
                            {showFailedUploadMessage===true && (
                                <MessageAlertWrapper>
                                    <MessageAlert
                                    titleText={'Upload failed'}
                                    bodyText={'Please try again.'}
                                    color={'yellow'}
                                    onClose={onFailedUploadMessageClose}
                                    includeClose={true}
                                    />
                                </MessageAlertWrapper>
                            )}
                            <UploadWrapper>
                                <UploadZone
                                    ref={dropzoneRef}
                                    assignmentId={assignmentId}
                                    onNewFile={handleNewFile}
                                    imageUploaded={imageUploaded}
                                    onNewThumbnail={handleNewThumbnail}
                                    uploadLoadingUpdated={setUploadLoading}
                                    onUploadStart={handleFileUploadStart}
                                    onUploadSuccess={handleFileUploadComplete}
                                    onUploadFailure={handleFileUploadFailed}
                                />
                                {filenames.map((filename: string, i) => (
                                    <FramesContainer1 key={i}>
                                        <Assignment onClick={() => {
                                        }}>
                                            <Panel/>
                                            <AssignmentInner>
                                                <IconParent>
                                                    <Icon size={32} 
                                                        icon={uploadStatuses[filename] === 'failed' ? 'exclamation' : 'page'} 
                                                        color={uploadStatuses[filename] === 'failed' ? Colors.yellow : (uploadStatuses[filename] === 'completed' ? Colors.grayMed : Colors.grayLight)} 
                                                    />
                                                    <AssignmentName value={uploadStatuses[filename] === 'failed' ? filename + ' (upload failed)' : filename} readOnly type="text"/>
                                                </IconParent>
                                            </AssignmentInner>
                                        </Assignment>
                                    </FramesContainer1>
                                ))}
                            </UploadWrapper>
                        </UploadContainer>
                    }
                    return <div/>
                })()}
            </ContentContainer>
        </OuterContainer>
}

const Scan: FunctionComponent = () => {
    const { assignmentId: assignmentIdParam } = useParams<{ assignmentId: string }>();

    const [assignmentId, setAssignmentId] = useState<string | null>(assignmentIdParam || null);
    const [imageId, setImageId] = useState<string | null>(null);
    const scannerRef = useRef<any>(null);
    const dropzoneRef = useRef<any>(null);

    const [aspectRatioState, setAspectRatioState] = useState<number>(1);

    const [isAdvancing, setIsAdvancing] = useState(false);

    const location = useLocation();
    const [thumbnail, setThumbnail] = useState<string | null>(null);

    const [scanCount, setScanCount] = useState<number>(0);

    const [mode, setMode] = useState<string>('scan');

    const [filenames, setFilenames] = useState([]);

    const navigate = useNavigate();
    const { scoreAssignment } = useScoreAssignment();

    const { data, loading, error, refetch } = useGetAssignment(assignmentId);

    const [uploadLoading, setUploadLoading] = useState(false);

    const [cameraStarted, setCameraStarted] = useState(false);

    const [hasAnswerKey, setHasAnswerKey] = useState(false);
    const [isAnswerKeyModalOpen, setAnswerKeyModalOpen] = useState(false);
    const [isOneImageModalOpen, setOneImageModalOpen] = useState(false);

    // Use this to force logout if auth error
    const { data: userInfoData, loading: userInfoLoading, error: userInfoError } = useGetUserInfo();

    const [showStudentWorkMessage, setShowStudentWorkMessage] = useState(false);

    useEffect(() => {
        if (assignmentId) {
            refetch()
        }
    }, [assignmentId]);

    useEffect(() => {
        // console.log(userInfoData);
        if (userInfoData && userInfoData.getUserInfo.hasAssignment === false) {
            setShowStudentWorkMessage(true);
            if (data && (data.getAssignment.images || []).length > 0) {
                setShowStudentWorkMessage(false);
            }
        }
    }, [userInfoData, data]);

    useEffect(() => {
        if (data && data.getAssignment && data.getAssignment.images && !uploadLoading && !loading) {
            setScanCount((data.getAssignment.images || []).length)
            if ((data.getAssignment.images || []).length == 0) {
                setThumbnail(null);
            } else {
                setThumbnail(data.getAssignment.images[data.getAssignment.images.length - 1].url);
            }

            const hasAnswerKey = data.getAssignment.images.some(image => image.isAnswerKey);
            setHasAnswerKey(hasAnswerKey);
        }
    }, [data, uploadLoading, loading]);

    // useEffect(() => { console.log('Answer Key: ' + hasAnswerKey); }, [data, hasAnswerKey]); 

    const onAnswerKeyModalSelectClick = () => {
        if (assignmentId) {
            setAnswerKeyModalOpen(false);
            navigate(`/answer-key/${assignmentId}`); 
        }
    };

    useEffect(() => {
        if (assignmentId) { 
            navigate(`/scan/${assignmentId}`, { replace: true }); // update url when assignmentId changes
        }
    }, [assignmentId, navigate]);

    const handleNewThumbnail = (newThumbnail) => {
        setScanCount(currentScanCount => currentScanCount + 1); 
        setThumbnail(newThumbnail);
    };

    const handleAspectRatioChange = (newAspectRatio) => {
        setAspectRatioState(newAspectRatio);
    };

    const onThumbnailClick = useCallback(() => {
        if (assignmentId !== null && assignmentId !== "null") {
            navigate(`/delete-images/${assignmentId}`);
        }
    }, [navigate, assignmentId]);

    const cameraSoundObject = new Audio(cameraSound);

    const playCameraSound = () => {
        cameraSoundObject.currentTime = 0; // Set the time to 0 to restart the sound
        cameraSoundObject.play();
    };

    const handleCaptureClick = () => {
        if (scannerRef.current) {
            playCameraSound();
            scannerRef.current.captureAndUpload();
        }
        if (dropzoneRef.current) {
            dropzoneRef.current.openFilePicker();
        }
    };

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
          if (event.code === 'Space') {
            event.preventDefault();
            handleCaptureClick();
          }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => { document.removeEventListener('keydown', handleKeyPress); };
      }, [handleCaptureClick]);

    const imageUploaded = (imageId: string, newAssignmentId: string | null) => {
        setAssignmentId(newAssignmentId);
        setImageId(imageId);
    };

    const handleNewFile = (filename: string) => {
        setFilenames(currentFilenames => [...currentFilenames, filename])
    };

    const handleCloseStudentWorkMessage = () => {
        setShowStudentWorkMessage(false);
      };

    return (
        <ScanRoot>
            <TopBarContainer mode={mode}>
                <FrameTopBar
                    onValueChange={(value) => {}}
                    onAdvance={async () => {
                        if (!hasAnswerKey) {
                            setAnswerKeyModalOpen(true);
                        }
                        else if (!(data?.getAssignment?.images?.some(image => !image.isAnswerKey))) {
                            setOneImageModalOpen(true);
                        }
                        else {
                            if (isAdvancing || !assignmentId) {
                                return;
                            }
                            setIsAdvancing(true);
                            await scoreAssignment(assignmentId, "RIGHTWRONG");
                            navigate(`/processing/${assignmentId}`);
                        }
                    }}
                    advanceDisabled={!assignmentId || uploadLoading || (data?.getAssignment?.images?.length === 0)}
                    assignmentId={assignmentId}
                    inProgress={_.get(data, 'getAssignment.status', null) == 'IN_PROCESS'}
                    keyDisabled={!assignmentId}
                />
            </TopBarContainer>
            <MessageAlertWrapper>
                {showStudentWorkMessage && (<MessageAlert
                    titleText={'Student Work'}
                    bodyText={'Capture or upload images of student work for this assignment.'}
                    color={'green'}
                    onClose={handleCloseStudentWorkMessage}
                    includeClose={true}
                />)}
            </MessageAlertWrapper>
            <MainZone
                mode={mode}
                aspectRatioState={aspectRatioState}
                scannerRef={scannerRef}
                assignmentId={assignmentId}
                imageUploaded={imageUploaded}
                handleAspectRatioChange={handleAspectRatioChange}
                handleNewThumbnail={handleNewThumbnail}
                setUploadLoading={setUploadLoading}
                dropzoneRef={dropzoneRef}
                handleNewFile={handleNewFile}
                filenames={filenames}
                onCameraStart={() => {
                    // console.log('Camera started outer');
                    setCameraStarted(true)
                }}
            />
            {isAnswerKeyModalOpen===true && <MessageModal 
                titleText={'Answer Key'} 
                bodyText={['Please scan and select an answer key before scoring.','Format your answer key just like a student assignment.']} 
                primaryButtonText={'Select'} 
                primaryButtonIcon={'key'} 
                secondaryButtonText={'Scan'} 
                showOverlay={true}
                onClick={onAnswerKeyModalSelectClick}
                onClose={()=> {setAnswerKeyModalOpen(false)}}
            />}
            {isOneImageModalOpen===true && <MessageModal 
                titleText={'Add Images'} 
                bodyText={'Please add at least one image of a student assignment in addition to an answer key before scoring.'} 
                primaryButtonText={'Scan'} 
                primaryButtonIcon={'camera'} 
                secondaryButtonText={'Cancel'}
                showOverlay={true}
                onClick={()=> {setOneImageModalOpen(false)}}
                onClose={()=> {setOneImageModalOpen(false)}}
            />}
            <FrameBottomBar
                mode={mode}
                mainDisabled={mode == 'scan' && !cameraStarted}
                onMainScanClick={handleCaptureClick}
                thumbnail={thumbnail}
                badgeCount={scanCount}
                onChangeMode={setMode}
                assignmentId={assignmentId}
                advanceDisabled={!assignmentId}
                onThumbnailClick={onThumbnailClick}
            />
        </ScanRoot>
    );
};

export default Scan;
