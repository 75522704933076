import { FunctionComponent } from "react";
import styled from "styled-components";
import Colors from './common/Colors';
import Fonts from './common/Fonts';


export type ButtonLinkType = {
    text?: string;
    onClick?: () => null;
};

const ButtonLinkRoot = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    color: ${Colors.blue};
    font-family: ${Fonts.outfitRegular.fontFamily};
    position: relative;
    line-height: 22px;
    font-weight: ${Fonts.outfitRegular.fontWeight};
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;

    &:hover {
        color: ${Colors.blueLight}; 
        text-decoration: underline;
    }

    &:active {
        color: ${Colors.blueDark};
        text-decoration: underline;
    }

    &:disabled {
        cursor: default;
    }
`;

const ButtonLink: FunctionComponent<ButtonLinkType> = ({ text, onClick = (() => {}) }) => {
    return (
        <ButtonLinkRoot onClick={onClick}>
            {text}
        </ButtonLinkRoot>
    );
};

export default ButtonLink;
