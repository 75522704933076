import React, { FunctionComponent, useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../components/basic/Input';
import Button from '../components/basic/Button';
import Worksheet from '../components/Worksheet';
import Colors from "../components/basic/common/Colors";
import { useGetAssignment, useRenameAssignment, useDeleteAssignment, useScoreAssignment, useExportAssignmentCSV } from '../apollo/useAssignments';
import { useRenameStudent, useOverrideScore } from '../apollo/useStudentAssignments';
import PositionedLayout from '../components/PositionedLayout';
import ButtonFixed from "../components/basic/ButtonFixed";
import _ from "lodash";
import Loading from '../components/basic/Loading';
import ErrorScreen from '../components/ErrorScreen';
import MessageModal from '../components/basic/MessageModal';
import MessageAlert from '../components/basic/MessageAlert';

const ReviewScoresRoot = styled.div`
    width: 100%;
    height: 100svh;
    position: relative;
    background-color: ${Colors.pale};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    overflow-x: hidden;
    overflow-y: hidden;
`;

const TopBar = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    z-index: 10;
    background-color: ${Colors.pale};
`;
const ButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
`;

const ScrollableContent = styled.div`
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    gap: 20px;
`;
const AssignmentGrid = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start; 
    gap: 20px;
    width: 100%;
    padding: 0px 10px 0px 10px; 
    margin: auto;
`;

const DeleteButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    padding: 10px 0px 20px 0px;
`;

const MessageAlertWrapper = styled.div`
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 100;
`;

const ReviewScores: FunctionComponent = () => {
    const [isAdvancing, setIsAdvancing] = useState(false);
    const [assignmentName, setAssignmentName] = useState('New Assignment');
    const navigate = useNavigate();
    const { assignmentId } = useParams<{ assignmentId: string }>();
    const { data, loading, error, refetch } = useGetAssignment(assignmentId);
    const { renameAssignment } = useRenameAssignment();
    const { renameStudent } = useRenameStudent();
    const { overrideScore } = useOverrideScore(assignmentId);
    const { scoreAssignment } = useScoreAssignment();
    const { deleteAssignment } = useDeleteAssignment();
    const { downloadCSV } = useExportAssignmentCSV();
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const [showErrorMessageAlert, setErrorMessageAlert] = useState(false);
    const [isAnswerKeyModalOpen, setAnswerKeyModalOpen] = useState(false);
    const [isOneImageModalOpen, setOneImageModalOpen] = useState(false);

    const [centerComponentWidth, setCenterComponentWidth] = useState(0);
    const centerComponentRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const updateCenterComponentWidth = () => {
            if (centerComponentRef.current) {
                setCenterComponentWidth(centerComponentRef.current.offsetWidth);
        }};
        updateCenterComponentWidth(); // initial update
        window.addEventListener('resize', updateCenterComponentWidth); // add event listener
        return () => {
            window.removeEventListener('resize', updateCenterComponentWidth); // clean up event listener
        };
    }, [assignmentName]);

    // scored worksheets list (sorted alphanumerically)
    const [sortedScoredWorksheets, setSortedScoredWorksheets] = useState([]);
    useEffect(() => {
        // check if sortedScoredWorksheets is empty to only sort once when loading the page
        if (data && data.getAssignment && data.getAssignment.studentAssignments && sortedScoredWorksheets.length === 0) {
            const scoredWorksheets = [...data.getAssignment.studentAssignments];
            const sorted = scoredWorksheets.sort((a, b) => {
                const regex = /(\d+)/;
                const partsA = a.studentName.split(regex);
                const partsB = b.studentName.split(regex);
                for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
                    if (partsA[i].toLowerCase() === partsB[i].toLowerCase()) continue;
                    if (!isNaN(Number(partsA[i])) && !isNaN(Number(partsB[i]))) {
                        return Number(partsA[i]) - Number(partsB[i]);
                    }
                    return partsA[i].toLowerCase().localeCompare(partsB[i].toLowerCase());
                }
                return partsA.length - partsB.length;
            });
            setSortedScoredWorksheets(sorted);
        }
    }, [data, sortedScoredWorksheets.length]);

    useEffect(() => {
        if (data && data.getAssignment) {
            setAssignmentName(data.getAssignment.name);
            
            if (data.getAssignment.status === 'ERROR') {
                if (data.getAssignment.errorMessage in errorMessages) {
                  setErrorMessageAlert(true);
                }
            }
        }
    }, [data]);

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        if (isDeleteModalOpen) {
            document.body.style.overflow = 'hidden'; 
        } else {
            document.body.style.overflow = '';
        }
        return () => { document.body.style.overflow = ''; };
    }, [isDeleteModalOpen]);

    const onCloseClick = useCallback(() => {
        navigate("/assignments");
    }, [navigate]);

    const onScanClick = useCallback(() => {
        const filteredImages = data.getAssignment.images.filter(image => !image.studentAssignmentId); // filter out scored images
    
        const lastImageThumbnail = filteredImages.length > 0 ? filteredImages[filteredImages.length - 1].url : '';
        const imageCount = filteredImages.length-1; 
        // console.log('number images: ' + imageCount);
    
        navigate(`/scan/${assignmentId}`, { state: { lastImageThumbnail, numImages: imageCount } });
    }, [navigate, assignmentId, data]);

    const handleInputChange = (value: string) => {
        setAssignmentName(value);
    };

    const handleInputBlur = () => {
        if (assignmentId) {
            renameAssignment(assignmentId, assignmentName).catch(console.error);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.currentTarget.blur(); // Trigger blur to handle rename
        }
    };

    const onRenameStudentWrapper = (studentAssignmentId, studentName) => {
        renameStudent(studentAssignmentId, studentName).catch(console.error);
    };

    const onCorrectScoreWrapper = (studentAssignmentId, scoreId, value) => {
        overrideScore(scoreId, value ? 1 : 0, "RIGHTWRONG")
          .then(() => {
            setSortedScoredWorksheets((prevWorksheets) => {
              return prevWorksheets.map((worksheet) => {
                if (worksheet.id === studentAssignmentId) {
                  const updatedScores = worksheet.scores.map((score) => {
                    if (score.id === scoreId) {
                      return { ...score, value: value ? 1 : 0 };
                    }
                    return score;
                  });
                  return { ...worksheet, scores: updatedScores };
                }
                return worksheet;
              });
            });
          })
          .catch(console.error);
      };

    const onDeleteAssignmentClick = () => {
        if (assignmentId) {
            setDeleteModalOpen(true); // open the confirmation modal when clicking delete
    } };

    const onDeleteAssignmentConfirmation = () => {
        if (assignmentId) {
            deleteAssignment(assignmentId)
                .then(() => {
                    setDeleteModalOpen(false); // close the modal
                    navigate("/assignments"); // navigate back to all assignments after deletion
                })
                .catch(console.error);
    } };

    const onAnswerKeyModalScanClick = () => {
        if (assignmentId) {
            setAnswerKeyModalOpen(false);
            navigate(`/scan/${assignmentId}`); 
        }
    };

    const onOneImageModalMainClick = () => {
        if (assignmentId) {
            setOneImageModalOpen(false);
            navigate(`/scan/${assignmentId}`); 
        }
    };

    const errorMessages = {
        ANSWER_KEY_ERROR: {
            titleText: 'Error Reading Answer Key',
            bodyText: 'Please upload an answer key that is formatted the same way as this student assignment.',
        },
        UNSUPPORTED_SUBJECT: {
            titleText: 'Unsupported Subject',
            bodyText: 'Snapalyze hasn\'t learned this subject yet. Check back soon!',
        }
    };

    if (loading) return <Loading></Loading>;
    if (error || !data) return <ErrorScreen></ErrorScreen>;

    // key worksheets list
    const keyWorksheets = (data.getAssignment.images || []).filter(image => image.isAnswerKey);

    // unscored worksheets list
    const unscoredWorksheets = (data.getAssignment.images || []).filter(image => !image.studentAssignmentId && !image.isAnswerKey).sort((a, b) => a.uploadDate - b.uploadDate) || [];

    return (
        <ReviewScoresRoot>
            <TopBar>
            <PositionedLayout
                leftComponent={
                    <ButtonGroup>
                        <Button color={'blue'} icon={'home'} onClick={onCloseClick} />
                        <Button color={'blue'} icon={'camera'} disabled={_.get(data, 'getAssignment.status' != 'OPEN')} onClick={onScanClick} />
                    </ButtonGroup>
                }
                centerComponent={
                    <div ref={centerComponentRef} style={{ margin: '0px 0px 8px 0px', maxHeight: '30px', width: '100%', display: 'flex', justifyContent: 'center' }}><Input
                        small={true}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        onKeyDown={handleKeyDown}
                        value={assignmentName}
                    />
                </div>}
                rightComponent={
                    <span>{(() => {
                        if (_.get(data, 'getAssignment.status') == 'OPEN' || (_.get(data, 'getAssignment.status') == 'DONE' && unscoredWorksheets.length > 0)) {
                           return <Button color={'blue'} text="Score All" icon="arrow-right" 
                           onClick={() => {
                                   (async () => {
                                        if (!data.getAssignment.images.some(image => image.isAnswerKey)) {
                                            setAnswerKeyModalOpen(true);
                                        }
                                        else if (!(data?.getAssignment?.images?.some(image => !image.isAnswerKey))) {
                                            setOneImageModalOpen(true);
                                        }
                                        else {
                                            if (isAdvancing || !assignmentId) {
                                                return;
                                            }
                                            setIsAdvancing(true);
                                            await scoreAssignment(assignmentId, "RIGHTWRONG");
                                            navigate(`/processing/${assignmentId}`);
                                        }
                                   })()
                           }}/>
                        }
                        if (_.get(data, 'getAssignment.status') == 'IN_PROCESS') {
                            return <Button color={'blue'} text="Scoring" icon={'loading'} disabled={true} />
                        }
                        return <Button color={'blue'} text="Export" icon={'table'} onClick={() => {}} disabled={!(_.get(data, 'getAssignment.status') == 'DONE')} onClick={() => {
                            (async () => {
                                await downloadCSV(assignmentId, assignmentName);
                            })()
                        }}/>
                    })()}</span>
                }
            /></TopBar>
            {showErrorMessageAlert===true && (<MessageAlertWrapper>
                <MessageAlert color='red' includeClose={true} onClose={() => {setErrorMessageAlert(false)}}
                    titleText={errorMessages[data.getAssignment.errorMessage].titleText}
                    bodyText={errorMessages[data.getAssignment.errorMessage].bodyText}
            /></MessageAlertWrapper>)}
            <ScrollableContent>
                <AssignmentGrid>
                    {keyWorksheets.map((image, index) => (
                        <Worksheet key={index} rawImage={image} answerKey={true} studentAssignment={null} onRenameStudent={onRenameStudentWrapper} onCorrectScore={onCorrectScoreWrapper} />
                    ))}
                    {sortedScoredWorksheets.map((studentAssignment) => (
                        <Worksheet key={studentAssignment.id} studentAssignment={studentAssignment} onRenameStudent={onRenameStudentWrapper} onCorrectScore={onCorrectScoreWrapper} failed={_.get(data, 'getAssignment.status') === 'ERROR'} />
                    ))}
                    {unscoredWorksheets.map((image, index) => (
                        <Worksheet key={index} rawImage={image} studentAssignment={null} onRenameStudent={onRenameStudentWrapper} onCorrectScore={onCorrectScoreWrapper} failed={_.get(data, 'getAssignment.status') === 'ERROR' || image.error === true } />
                    ))}
                    <DeleteButtonWrapper>
                        <ButtonFixed red icon={'delete-fill'} text={"DELETE ASSIGNMENT"} onClick={onDeleteAssignmentClick} />
                    </DeleteButtonWrapper>
                </AssignmentGrid>
            </ScrollableContent>
            {isDeleteModalOpen===true && <MessageModal
                titleText={'Delete Assignment'}
                bodyText={'Are you sure? This action cannot be undone.'}
                primaryButtonText={'Delete'}
                primaryButtonIcon={'delete-fill'}
                primaryButtonColor={'red'}
                secondaryButtonText={'Cancel'}
                showOverlay={true}
                onClick={onDeleteAssignmentConfirmation}
                onClose={()=> {setDeleteModalOpen(false)}}
            />}
            {isAnswerKeyModalOpen===true && <MessageModal 
                titleText={'Answer Key'} 
                bodyText={['Please scan and select an answer key before scoring.','Format your answer key just like a student assignment.']} 
                primaryButtonText={'Scan'} 
                primaryButtonIcon={'key'} 
                secondaryButtonText={'Cancel'} 
                showOverlay={true}
                onClick={onAnswerKeyModalScanClick}
                onClose={()=> {setAnswerKeyModalOpen(false)}}
            />}
            {isOneImageModalOpen===true && <MessageModal 
                titleText={'Add Images'} 
                bodyText={'Please add at least one image of a student assignment in addition to an answer key before scoring.'} 
                primaryButtonText={'Scan'} 
                primaryButtonIcon={'camera'} 
                secondaryButtonText={'Cancel'}
                showOverlay={true}
                onClick={onOneImageModalMainClick}
                onClose={()=> {setOneImageModalOpen(false)}}
            />}
        </ReviewScoresRoot>
    );
};

export default ReviewScores;
