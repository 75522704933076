import { FunctionComponent, useState } from "react";
import styled, { css } from "styled-components";
import Icon from './common/Icon';
import Colors, {blendWithWhite} from './common/Colors';
import Fonts from './common/Fonts';

const Text1 = styled.div<{ white?: boolean, hover?: boolean, active?: boolean, disabled?: boolean }>`
    font-size: 18px;
    line-height: 28px;
    font-weight: ${Fonts.outfitRegular.fontWeight};
    font-family: ${Fonts.outfitRegular.fontFamily};
    color: ${({ color }) => color};
    text-align: center;
`;

const ButtonPrimaryRoot = styled.button<{ white?: boolean, red?: boolean, disabled?: boolean }>`
    width: 300px;
    max-width: 100%;
    height: 52px;
    border: ${({ white, disabled }) => white && !disabled ? `1px solid transparent` : "none"};
    padding: 0;
    background-color: ${({ disabled, white, red }) => white ? Colors.white : disabled ? blendWithWhite((red ? Colors.red : Colors.blue), 0.4) : (red ? Colors.red : Colors.blue)};
    border-radius: 42px;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin: auto;

    &:hover, &:focus {
        ${({ white, red, disabled }) => {
            if (disabled) return ''; // don't do anything if button is disabled

            return css`background-color: ${white ? Colors.white : red ? Colors.redLight : Colors.blueLight}; cursor: pointer;`;
        }}

    &:active {
        ${({ white, red, disabled }) => {
            if (disabled) return ''; // don't do anything if button is disabled

            const backgroundColor = white ? Colors.white : red ? Colors.redDark : Colors.blueDark;

            const border = white ? '1px solid ' + Colors.blueDark : 'none'; // if white button, add dark blue outline

            return css`
                background-color: ${backgroundColor}; 
                cursor: pointer;
                ${border && `border: ${border};`}`;
        }
    }

    &:disabled {
        cursor: default;
    }
`;

export type ButtonFixedType = {
    text: string;
    icon?: string;
    onClick: () => void;
    disabled?: boolean;
    white?: boolean;
    red?: boolean;
};

const ButtonFixed: FunctionComponent<ButtonFixedType> = ({
                                                             text,
                                                             icon,
                                                             onClick,
                                                             disabled = false,
                                                             white = false,
                                                             red = false
                                                         }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const iconColor = disabled ? (white ? blendWithWhite(Colors.blue, 0.4) : Colors.white) :
        white ? (isActive ? Colors.blueDark : isHovered ? Colors.blueLight : Colors.blue) :
            Colors.white;

    const onClickRaw = (e) => {
        e.preventDefault()
        onClick();
    }

    return (
        <ButtonPrimaryRoot
            onClick={onClickRaw}
            disabled={disabled}
            white={white}
            red={red}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onMouseDown={() => setIsActive(true)}
            onMouseUp={() => setIsActive(false)}
        >
            <Text1 color={iconColor}>{text}</Text1>
            {icon && <Icon icon={icon} color={iconColor} size={22} />}
        </ButtonPrimaryRoot>
    );
};

export default ButtonFixed;
